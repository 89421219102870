import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemText,
    ListItemButton,
    Collapse,
    Typography,
    Card,
    CardContent,
    Grid,
    Icon,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import 設備情報Icon from '../../../../assets/images/icons/color/設備情報.svg';
import お知らせIcon from '../../../../assets/images/icons/color/お知らせ.svg';
import 理事会資料Icon from '../../../../assets/images/icons/color/理事会資料.svg';
import 注意事項Icon from '../../../../assets/images/icons/color/注意事項.svg';
import 工事Icon from '../../../../assets/images/icons/color/工事.svg';
import 工程表Icon from '../../../../assets/images/icons/color/工程表.svg';
import 書類一覧Icon from '../../../../assets/images/icons/color/書類一覧.svg';
import NEWIcon from '../../../../assets/images/icons/color/NEW.svg';

import { getPublicationsByCategory, getMansionData } from '../../../../api/datas';
import { getFileUrl } from '../../../../api/datas';

import FileViewerDialog from '../../../common/FileViewerDialog'; // FileViewerDialog をインポート

interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationCategory {
    name: string;
    data: Publication[];
}

interface PublicationListProps {
    view: 'resident' | 'vendor';
}
const PublicationList: React.FC<PublicationListProps> = ({ view }) => {
    const [mansionData, setMansionData] = useState<{} | null>(null)
    const [publications, setPublications] = useState<PublicationCategory[]>([]);
    const [folders, setFolders] = useState({
        resident: ['設備情報', 'お知らせ', '理事会資料', '注意事項'],
        vendor: ['工事', 'お知らせ', '工程表', '書類一覧', '注意事項'],
    });
    const [openFolder, setOpenFolder] = useState<string | null>(null);
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<Publication | null>(null);
    const [openFileViewer, setOpenFileViewer] = useState(false); // FileViewerDialog の開閉状態を管理する state
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null); // 選択されたファイルの URL を保持する state
    const [newsForContractor, setNewsForContractor] = useState<Publication[]>([]);
    const [newsForResident, setNewsForResident] = useState<Publication[]>([]);
    const fetchPublications = async (selectedCategory: string) => {
        try {
            const data = await getPublicationsByCategory(selectedCategory);
            setPublications(data);
        } catch (error) {
            console.error('Error fetching publications:', error);
        }
    };
    useEffect(() => {
        getMansionData().then(result => {
            setMansionData(result);
            if (result?.categoryForContractor) {
                setFolders({ ...folders, vendor: result.categoryForContractor })
            }
            if (result?.categoryForResident) {
                setFolders({ ...folders, resident: result.categoryForResident })
            }
            if (result?.newsForContractor) {
                setNewsForContractor(result.newsForContractor)
            }
            if (result?.newsForResident) {
                setNewsForResident(result.newsForResident)
            }
        })
    }, []);

    // サブカテゴリ名に対応するアイコンを取得する関数
    const getIcon = (folder: string) => { // 戻り値の型を React.FunctionComponent に変更
        switch (folder) {
            case '設備情報':
                return 設備情報Icon;
            case 'お知らせ':
                return お知らせIcon;
            case '理事会資料':
                return 理事会資料Icon;
            case '注意事項':
                return 注意事項Icon;
            case '工事':
                return 工事Icon;
            case '工程表':
                return 工程表Icon;
            case '書類一覧':
                return 書類一覧Icon;
            case '洗濯物情報':
                return 工事Icon;
            // ... 他のサブカテゴリに対応するアイコンを設定
            default:
                return 'null';
        }
    };

    const handleFolderClick = (folder: string) => {
        setOpenFolder(folder);
        setOpenCategory(null); // フォルダを開いたときにカテゴリを閉じる
        setSelectedPost(null); // フォルダを開いたときに選択された投稿をリセット
        fetchPublications(folder);
    };

    const handleCategoryClick = (category: string) => {
        if (category === openCategory) {
            setOpenCategory(null);
        } else {
            setOpenCategory(category);
        }
        setSelectedPost(null); // カテゴリを開いたときに選択された投稿をリセット
    };

    const handlePostClick = (post: Publication) => {
        setSelectedPost(post);
    };

    const handleAttachmentClick = async (fileUrl: string) => {
        setSelectedFileUrl(fileUrl);
        setOpenFileViewer(true);
    };

    const handleCloseFileViewer = () => {
        setOpenFileViewer(false);
        setSelectedFileUrl(null);
    };
    function extractFilename(url: string) {
        try {
            const urlObj = new URL(url);
            let pathname = urlObj.pathname;
            // 2回URLデコード
            pathname = decodeURIComponent(pathname);
            pathname = decodeURIComponent(pathname);
            const filenameWithParams = pathname.substring(pathname.lastIndexOf('/') + 1);
            const filename = filenameWithParams.split('?')[0]; // クエリパラメータを除去
            return filename;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }
    return (
        <div style={{ textAlign: 'center', width: '100%', height: '100%', marginTop: '10px' }}>
            {/* フォルダボタン */}
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px'
                }}>
                {folders[view].map((folder) => ( // category に基づいてフォルダを表示
                    <Button
                        key={folder}
                        variant="contained"
                        onClick={() => handleFolderClick(folder)}
                        sx={{
                            backgroundColor: 'white',
                            ':hover': { backgroundColor: 'white' },
                            border: '3px solid #313131',
                            borderRadius: '14px',
                            color: 'black',
                            height: '90%',
                            width: '18%',
                            mb: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0px'
                        }}
                    >
                        <Typography sx={{ mt: -5 }}><img width={window.screen.width > 1800 ? '100%' : '90%'} height={window.screen.width > 1800 ? '100%' : '90%'} src={getIcon(folder)} alt={folder} /></Typography>
                        <Typography sx={{ mt: window.screen.width > 1800 ? -3 : -6, fontSize: '2vh' }}>{folder}</Typography>
                    </Button>
                ))}
            </div>

            {/* ダイアログ */}
            <Dialog open={!!openFolder} onClose={() => setOpenFolder(null)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "70vw"
                        },
                    },
                }}>
                <DialogTitle sx={{ fontSize: '2vh' }}>{openFolder}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* 左側：カテゴリ */}
                        <Grid item xs={4}>
                            {publications.length > 0 ?
                                <List sx={{ height: '60vh', overflow: 'scroll' }}>
                                    {publications
                                        .map((publication) => (
                                            <div key={publication.name}>
                                                <ListItemButton
                                                    onClick={() => handleCategoryClick(publication.name)}
                                                    sx={{ backgroundColor: '#b9b9b9', ':hover': { backgroundColor: '#b9b9b9' }, border: '2px solid  #757272' }}
                                                >
                                                    <img src={NEWIcon} alt="NEW" style={{ height: '7vh',margin:'-10px',display:publication.data.some(post => post.createdAt && new Date(post.createdAt._seconds * 1000).toDateString() == new Date().toDateString()) ? 'block' : 'none'}} />
                                                    <ListItemText
                                                        primary={<Typography sx={{ fontSize: '2vh' }}>{publication.name}</Typography>}
                                                    />
                                                    {openCategory === publication.name ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={openCategory === publication.name} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {publication.data.map((post) => (
                                                            <ListItemButton key={post.id}
                                                                onClick={() => handlePostClick(post)}
                                                                sx={{ border: '2px solid  #757272' }}
                                                            >
                                                                <img src={NEWIcon} alt="NEW" style={{ height: '7vh',margin:'-10px',display:post.createdAt && new Date(post.createdAt._seconds * 1000).toDateString() == new Date().toDateString() ? 'block' : 'none'}} />
                                                                <ListItemText 
                                                                    primary={<Typography sx={{ fontSize: '1.5vh' }}>{post.title || 'タイトルなし'}</Typography>} />
                                                            </ListItemButton>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            </div>
                                        ))}
                                </List>
                                :
                                <Typography sx={{ fontSize: '2vh' }}>掲載がありません</Typography>
                            }
                        </Grid>

                        {/* 右側：掲載内容 */}
                        <Grid item xs={8}>
                            <Card variant="outlined" sx={{ height: '60vh', overflow: 'scroll', backgroundColor: '#e2dede', borderRadius: '20px' }}>
                                {selectedPost &&
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom sx={{ fontSize: '2vh' }}>
                                            {selectedPost.title || selectedPost.id}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: '2vh' }}>
                                            {selectedPost.startDate} - {selectedPost.endDate}
                                        </Typography>
                                        <Card sx={{ height: '35vh', overflow: 'scroll', backgroundColor: 'white', border: '2px solid  #757272', mb: 2 }}>
                                            <CardContent>
                                                {selectedPost.details && <Typography variant="body1" sx={{ fontSize: '2vh' }}>{selectedPost.details}</Typography>}
                                            </CardContent>
                                        </Card>
                                        {selectedPost.attachmentUrls && selectedPost.attachmentUrls.length > 0 && (
                                            <div>
                                                {selectedPost.attachmentUrls.map((url, index) => (
                                                    <Grid direction={'row'} gap={2} container key={index} sx={{ width: '100%' }}>
                                                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #757272', borderRadius: '10px' }}>
                                                            {/* 添付ファイルの表示 */}
                                                            <Typography
                                                                variant="body2"
                                                                onClick={() => handleAttachmentClick(url)}
                                                                sx={{ cursor: 'pointer', color: '#20baee', fontSize: '2vh'}}
                                                            >
                                                                {/**URLからファイル名だけを抜粋 */}
                                                                {extractFilename(url)}
                                                            </Typography>
                                                            <OpenInNewIcon color='inherit'  sx={{ fontSize: '2vh' }}/>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </div>
                                        )}
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* FileViewerDialog */}
            <FileViewerDialog
                fileUrl={selectedFileUrl || ''} // 選択されたファイルの URL を渡す
                open={openFileViewer}
                onClose={handleCloseFileViewer}
            />
        </div>
    );
};

export default PublicationList;