import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography
} from '@mui/material';

import Question from './Question';

// Questionコンポーネントの型定義を再利用
interface QuestionProps {
    question: {
        id: string;
        text: string;
        type: 'text' | 'number' | 'dropdown' | 'checkbox' | 'date' | 'multiple-date' | 'maru-batsu';
        options?: string[];
        min?: number;
        max?: number;
        startDate?: string;
        endDate?: string;
        dateRanges?: { startDate: string; endDate: string }[];
    };
    answer: any;
    onAnswerChange: (questionId: string, answer: any) => void;
    editable: boolean; // 常にfalse
    isPreview?: boolean;
    onQuestionChange: (questionId: string, question: any) => void;
    isEditing?: boolean;
}


interface QuestionnaireResponseDialogProps {
    open: boolean;
    onClose: () => void;
    questionnaire: any; // 回答対象のアンケートデータ
    onSubmit: ( questionnaireId:string,answer: any) => void;
}

const QuestionnaireResponseDialog: React.FC<QuestionnaireResponseDialogProps> = ({
    open,
    onClose,
    questionnaire,
    onSubmit
}) => {
    const [answers, setAnswers] = useState<any>({});

    useEffect(() => {
        if (questionnaire) {
            // 初期回答をquestionsに基づいて作成
            const initialAnswers = questionnaire.questions.reduce((acc:any, question:any) => {
                acc[question.id] = null; // または適切な初期値を設定
                return acc;
            }, {});
            setAnswers(initialAnswers);
        }

    }, [questionnaire]);


    const handleAnswerChange = (questionId: string, answer: any) => {
        console.log(questionId, answer)
        setAnswers({ ...answers, [questionId]: answer });
    };

    const handleSubmit = async() => {
        await onSubmit(questionnaire.id,answers);
        onClose();
        setAnswers({}); // 回答をリセット
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{questionnaire?.title} - 回答</DialogTitle>
            <DialogContent>
                <Box>
                    {questionnaire?.attachmentUrls.length > 0 && questionnaire?.attachmentUrls.map((url: string,index:number) => (
                        <img src={url} alt={`添付ファイル${index + 1}`} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                    ))
                    }
                </Box>
                {questionnaire?.questions.map((question: QuestionProps['question'], index: number) => (
                    <Box key={question.id} sx={{ mt: 2, border: '1px solid #ccc', p: 2 }}>
                        <Typography variant="subtitle1">質問 {index + 1}</Typography>
                        <Question
                            key={`${question.id}-${question.type}`}
                            question={question}
                            answer={answers[question.id]}
                            editable={true}
                            onAnswerChange={(id,answer) => handleAnswerChange(id, answer)}
                            isEditing={false}
                            onQuestionChange={() => {}}
                        />
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    回答を送信
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuestionnaireResponseDialog;