import React from 'react';
import { useState } from 'react';
import {
    Typography,
    Grid,
    Link,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';

//import original icon
import 工事責任者Icon from '../../../../assets/images/icons/color/工事責任者.svg';
import 管理会社Icon from '../../../../assets/images/icons/color/管理会社.svg';

interface MansionData {
    contact1_tel: string;
    contact2_tel: string;
    contact1: string;
    contact2: string;
}

interface ContactInfoProps {
    mansionData: MansionData;
    displayType: 'contact1' | 'both';
}

const ContactInfo: React.FC<ContactInfoProps> = ({ mansionData, displayType }) => {
    const { contact1_tel, contact2_tel, contact1, contact2 } = mansionData;
    const [open, setOpen] = useState(false);
    return (
        <Box sx={{
            border: '3px solid black',
            borderRadius: '10px',
            mt: 0.5,
            mr: 2,
            ml: 2
        }}
        >
            <Grid container onClick={() => setOpen(true)}>
                <Grid item xs={2}>
                    <img width={'80%'} height={'80%'} src={管理会社Icon} />
                </Grid>
                <Grid item xs={10}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',

                        }}
                    >
                        <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                            {contact1}
                        </Typography>
                        <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                            {contact1_tel}
                        </Typography>
                    </Box>
                </Grid>

                {displayType === 'both' && (
                    <>
                        <Grid item xs={2}>
                            <img width={'80%'} height={'80%'} src={工事責任者Icon} />
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                    {contact2}
                                </Typography>
                                <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                    {contact2_tel}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl"> 
                <DialogTitle>
                    <Typography sx={{fontSize:'2vh'}}>
                        連絡先
                    </Typography>
                    </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={2}>
                            <img  width={'80%'} height={'80%'} src={管理会社Icon} />
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                onClick={() => {
                                    window.open(`tel:${contact1_tel}`, '_blank');
                                }}
                            >
                                <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                    {contact1}
                                </Typography>
                                <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                    {contact1_tel}
                                </Typography>
                            </Box>
                        </Grid>
                        {displayType === 'both' && (
                            <>
                                <Grid item xs={2}>
                                    <img width={'80%'} height={'80%'} src={工事責任者Icon} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        onClick={() => {
                                            window.open(`tel:${contact2_tel}`, '_blank');
                                        }}
                                    >
                                        <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                            {contact2}
                                        </Typography>
                                        <Typography variant="body1" noWrap sx={{fontSize:'2vh'}}>
                                            {contact2_tel}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        <Typography sx={{fontSize:'2vh'}}>
                        閉じる
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ContactInfo;