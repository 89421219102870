import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Drawer
} from '@mui/material';
import { getFileUrl } from '../../../../api/datas';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//ファイル表示用
import FileViewerDialog from '../../../common/FileViewerDialog';

interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationProps {
    newsData: Publication[];
}

const NewPublication: React.FC<PublicationProps> = ({ newsData }) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<Publication | null>(null);
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
    const settings = {
        fade: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false, // 画像が複数ある場合のみ矢印を表示
        // 以下を追加
        adaptiveHeight: false, // 画像の高さを自動調整
    };

    const handleSelectPost = (post: Publication) => {
        setSelectedPost(post);
        setOpenDrawer(true);
    };
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };
    function extractFilename(url: string) {
        try {
            const urlObj = new URL(url);
            let pathname = urlObj.pathname;

            // 2回URLデコード
            pathname = decodeURIComponent(pathname);
            pathname = decodeURIComponent(pathname);


            const filenameWithParams = pathname.substring(pathname.lastIndexOf('/') + 1);
            const filename = filenameWithParams.split('?')[0]; // クエリパラメータを除去
            return filename;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }
    const handleCloseFileViewer = () => {
        setOpenFileViewer(false);
        setSelectedFileUrl(null);
    };
    const handleOpenFileDialog = (url: string) => {
        setSelectedFileUrl(url);
        setOpenFileViewer(true);
    };
    return (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
                height: '95%',
                mt: 1,
                mr: 2,
                ml: 2,
                position: 'relative'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h6">新着情報</Typography>
            </Box>
            <Divider sx={{ border: '1px solid black', width: '60%' }} />
            {newsData === null && <p>Loading...</p>}
            <List sx={{ width: '100%' }}>
                {newsData?.map((data, index) => (
                    <ListItem
                        key={index}
                        disablePadding
                        sx={{
                            height: '15%',
                            width: '90%',
                            border: '3px solid #5c5c5b',
                            borderRadius: '10px',
                            mb: 1,
                            overflow: 'hidden'
                        }}>
                        <ListItemButton
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '100%',
                                width: '100%',
                                p: 0,
                                pl: 1
                            }}
                            onClick={() => handleSelectPost(data)}
                        >
                            <ListItemText
                                sx={{ p: 0 }}
                                primary={<Typography variant="body1"  sx={{
                                    fontSize: '2vh',
                                    overflow: 'hidden',        // はみ出た部分を隠す
                                    textOverflow: 'ellipsis', // 省略記号 (...) を表示
                                    whiteSpace: 'nowrap',    // 折り返しを禁止
                                    display: 'block',         // ブロックレベル要素にする (width を有効にするため)
                                }}>{data.title || 'タイトルなし'}</Typography>}
                            >

                            </ListItemText>


                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {/* ダイアログ */}
            <Drawer
                anchor='top'
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                sx={{
                    height: '50vh', // 例: 高さ80%
                    '& .MuiDrawer-paper': { // Drawerのpaper部分
                        height: '50vh'
                    }
                }}>

                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {selectedPost &&
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h6" >
                                新着情報
                            </Typography>
                            <Divider sx={{ border: '2px solid #757272' }} />
                            <Typography variant="h6" >
                                {selectedPost.title || selectedPost.id}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {selectedPost.startDate} - {selectedPost.endDate}
                            </Typography>
                            <Box sx={{ height: '10vh', overflow: 'scroll', border: '1px solid #757272', borderRadius: '5px', p: 1 }}>
                                {selectedPost.details && <Typography variant="body1">{selectedPost.details}</Typography>}
                            </Box>
                            {selectedPost.attachmentUrls && selectedPost.attachmentUrls.map((url, index) => (
                                url?.indexOf('pdf') > -1 &&
                                <Box key={index} sx={{ mt: 1, mb: 1 }}>
                                        <Typography 
                                        variant='body1' 
                                        sx={{ 
                                            textDecoration: 'underline', 
                                        cursor: 'pointer' 
                                        }}
                                        onClick={() => {
                                            handleOpenFileDialog(String(new URL(url)))
                                        }}

                                        >
                                            {extractFilename(url)}
                                        </Typography>
                                </Box>
                            ))}
                            {selectedPost.attachmentUrls && selectedPost.attachmentUrls.length > 0 && (
                                <Box>
                                    <Slider {...settings}>
                                        {selectedPost.attachmentUrls.map((url, index) => (
                                            !(url?.indexOf('pdf') > -1) &&
                                            <div key={index}>
                                                <img src={url} alt={`attachment-${index}`} style={{ width: '100%', height: '100%' }} />
                                            </div>
                                        ))}
                                    </Slider>

                                </Box>
                            )}
                        </Box>
                    }
                </Box>

            </Drawer>
            <FileViewerDialog
                fileUrl={selectedFileUrl || ''}
                open={openFileViewer}
                onClose={handleCloseFileViewer}
            />
        </Box>
    );
};

export default NewPublication;