// src/api/delete.ts
import * as api from './api'; // api.ts をインポート

export const deleteSubCategory = async (
    category: string,
    subcategory: string
) => {
    const accountId = sessionStorage.getItem("accountId");
    const mansionId = sessionStorage.getItem("mansionId");
    try {
        const response = await fetch(api.deleteSubCategoryEndpoint+`?accountId=${accountId}&mansionId=${mansionId}&category=${category}&subcategory=${subcategory}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } catch (error) {
        console.error('Error creating subcategory:', error);
        throw error;
    }
}

export const deletePublish = async (
    category: string,
    subcategory: string,
    publishId: string
) =>  {
    const accountId = sessionStorage.getItem("accountId");
    const mansionId = sessionStorage.getItem("mansionId");
    try {
        const response = await fetch(api.deletePublishEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                category,
                subcategory,
                publishId
            })
        })
    } catch(error) {
        console.error('Error deleting publish:', error);
        throw error;
    }
}

export const deleteResidentAccount = async (
    id: string
) => {
    const accountId = sessionStorage.getItem("accountId");
    try {
        const response = await fetch(api.deleteResidentAccountEndpoint+`?accountId=${accountId}&id=${id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } catch (error) {
        console.error('Error deleting resident account:', error);
        throw error;
    }
}

export const deleteResidentGroup = async (
    id: string
) => {
    const accountId = sessionStorage.getItem("accountId");
    try {
        const response = await fetch(api.deleteResidentGroupEndpoint+`?accountId=${accountId}&id=${id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } catch (error) {
        console.error('Error deleting resident group:', error);
        throw error;
    }
}

export const deleteMainImage = async (
    id:string
) => {
    const accountId = sessionStorage.getItem("accountId");
    const mansionId = sessionStorage.getItem("mansionId");
    try {
        const response = await fetch(api.deleteMainImageEndpoint+`?accountId=${accountId}&mansionId=${mansionId}&imageId=${id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } catch (error) {
        console.error('Error deleting main image:', error);
        throw error;
    }
}

export const deleteQuestionnaire = async (
    id:string
) => {
    const accountId = sessionStorage.getItem("accountId");
    const mansionId = sessionStorage.getItem("mansionId");
    try {
        const response = await fetch(api.deleteQuestionnaireEndpoint,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                mansionId,
                id
            })
        });
    } catch (error) {
        console.error('Error deleting questionnaire:', error);
        throw error;
    }
}
