import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import PdfViewer from './PdfViewer';
import ExcelViewer from './ExcelViewer';
import ImageViewer from './ImageViewer';

interface Props {
  fileUrl: string;
  open: boolean;
  onClose: () => void;
}

const FileViewerDialog: React.FC<Props> = ({ fileUrl, open, onClose }) => {
  const [fileType, setFileType] = useState<"pdf" | "excel" | "image" | null>(null);

  useEffect(() => {
    if (!fileUrl) {
      return;
    }
    try {
      console.log('FILE:', fileUrl)
      new URL(fileUrl);
      // fileUrl が有効な URL の場合の処理
    } catch (error) {
      console.error('無効な URL です', error);
      // 
    }
    const url = new URL(fileUrl);
    const extension = url.pathname.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        setFileType("pdf");
        break;
      case 'xlsx':
      case 'xls':
        setFileType("excel");
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        setFileType("image");
        break;
      default:
        setFileType(null);
    }
  }, [fileUrl]);

  return (
    <Dialog open={open} onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "60vw",  // Set your width here
          },
        },
      }}>
      <DialogContent>
        {(() => {
          switch (fileType) {
            case 'pdf':
              return <PdfViewer fileUrl={fileUrl} />;
            case 'excel':
              return <ExcelViewer fileUrl={fileUrl} />;
            case 'image':
              return <ImageViewer fileUrl={fileUrl} />;
            default:
              return <div>対応していないファイル形式です<br />{fileUrl}</div>;
          }
        })()}
      </DialogContent>
    </Dialog>
  );
};

export default FileViewerDialog;