import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemButton,
  Typography,
  Card,
  Grid,
} from '@mui/material';
//icons
import PersonIcon from '@mui/icons-material/Person';
//common
import Question from '../common/Question'; // Question コンポーネントをインポート

interface AnswerListDialogProps {
  open: boolean;
  onClose: () => void;
  questionnaire: any; // アンケートを受け取る
}

const AnswerListDialog: React.FC<AnswerListDialogProps> = ({ open, onClose, questionnaire }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<any | null>(null); // 選択された回答を保持するstate
  const [isOpenAllAnswerDialog, setIsOpenAllAnswerDialog] = useState(false);

  const handleSelect = (userId: string) => {
    if (!questionnaire.answers) {
      console.log(questionnaire);
      alert('不明なエラー');
      return;
    }
    // 回答者IDに対応する回答を検索
    const answer = questionnaire?.answers?.find((answer: any) => answer.residentId === userId);
    setSelectedAnswer(answer);
  };

  const handleCloseAnswerDialog = () => {
    setSelectedAnswer(null);
  };

  const handleCloseAllAnswerDialog = () => {
    setIsOpenAllAnswerDialog(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle align='center'>{questionnaire?.title}</DialogTitle>
        <DialogContent>
          <Typography align='center'>
            【回答一覧】
          </Typography>

          <br />
          {/* 回答者リスト */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' textAlign={'center'}>回答者</Typography>
              <Card style={{ border: '1px solid gray', boxShadow: ' 0 0px 0px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px', height: '30vh', overflow: 'scroll' }}>
                <List>
                  {Array.isArray(questionnaire?.respondent) && questionnaire.respondent?.map((user: any) => (
                    <ListItemButton key={user.id} onClick={() => handleSelect(user.id)}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={user.name} secondary={'号室：' + user.roomNumber} />
                    </ListItemButton>
                  ))}
                </List>
              </Card>
            </Grid>
            <Grid item xs={6}>

              {/* 未回答者リスト */}
              <Typography variant='h6' textAlign={'center'}>未回答者</Typography>
              <Card style={{ border: '1px solid gray', boxShadow: ' 0 0px 0px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px', height: '30vh', overflow: 'scroll' }}>
                <List>
                  {Array.isArray(questionnaire?.non_respondent) && questionnaire.non_respondent?.map((user: any) => (
                    <ListItem key={user.id}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={user.name} secondary={'号室：' + user.roomNumber} />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            sx={{
              backgroundColor: 'white !important',
              color: 'red !important',
              ':hover': {
                backgroundColor: 'whitesmoke !important'
              }
            }}
            onClick={onClose}
          >
            閉じる
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#20baee !important',
              color: 'white !important',
              ':hover': {
                backgroundColor: '#1a9ecb !important'
              }
            }}
            onClick={() => setIsOpenAllAnswerDialog(true)}
          >
            すべての回答内容
          </Button>
        </DialogActions>
      </Dialog>
      {/* 個別の回答内容を表示するダイアログ */}
      {selectedAnswer && (
        <Dialog open={!!selectedAnswer} onClose={handleCloseAnswerDialog} fullWidth>
          <DialogTitle>回答内容</DialogTitle>
          <DialogContent>
            {questionnaire.questions?.map((question: any) => (
              <Question
                key={question.id}
                question={question}
                answer={selectedAnswer.answer[question.id]} // 回答データを設定
                editable={false}
                onAnswerChange={() => { }}
              />
            ))}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant='contained'
              sx={{
                'backgroundColor': '#20baee !important',
                'color': 'white !important',
                ':hover': {
                  'backgroundColor': '#1a9ecb !important'
                }
              }}
              onClick={handleCloseAnswerDialog}>
              戻る
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={isOpenAllAnswerDialog} onClose={handleCloseAllAnswerDialog} fullWidth>
        <DialogTitle>回答内容</DialogTitle>
        <DialogContent>
          {questionnaire?.answers?.map((answer: any) => (
            <Card key={answer.residentId} sx={{ p: 1, border: '1px solid gray', boxShadow: ' 0 2px 2px 0 rgba(0, 0, 0, 0.2)', }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    questionnaire?.respondent.find(
                      (user: any) =>
                        user.id === answer.residentId
                    ).name
                  }
                  secondary={
                    '号室：' +
                    questionnaire?.respondent.find(
                      (user: any) =>
                        user.id === answer.residentId)
                      .roomNumber}
                />
              </ListItem>
              {questionnaire?.questions?.map((question: any) => (
                <Question
                  key={question.id}
                  question={question}
                  answer={answer.answer[question.id]}
                  editable={false}
                  onAnswerChange={() => { }}
                />
              ))}
            </Card>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant='contained'
              sx={{
                'backgroundColor': '#20baee !important',
                'color': 'white !important',
                ':hover': {
                  'backgroundColor': '#1a9ecb !important'
                }
              }}
              onClick={handleCloseAllAnswerDialog}>
              戻る
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default AnswerListDialog;