import React, { useState, useEffect, use } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// ページコンポーネントのインポート
import VendorLogin from './components/features/vendors/pages/VendorLogin';
import VendorDashboard from './components/features/vendors/pages/VendorDashboard';
import VendorManagement from './components/features/vendors/pages/VendorManagement';
import ResidentLogin from './components/features/residents/pages/ResidentLogin';
import ResidentMypage from './components/features/residents/pages/ResidentMypage/ResidentMypage';
import SignageTemplate1 from './components/features/signage/SignageTemplate1';
import SignageTemplate2 from './components/features/signage/SignageTemplate2';
import SignageTemplate3 from './components/features/signage/SignageTemplate3';
import SignageTemplate4 from './components/features/signage/SignageTemplate4';
import SignageTemplate5 from './components/features/signage/SignageTemplate5';

import { LoadingModalProvider } from './components/common/LoadingModalContext';
import LoadingModal from './components/common/LoadingModal';


const theme = createTheme({
  palette: {
    custom: { main: '#65a7e9', light: '#8ecde2' }, // custom という名前で新しいカラーパレットを定義
  },
});

const App: React.FC = () => {
  const [accountId, setAccountId] = useState<string | null>(sessionStorage.getItem('accountId'));
  const [mansionId, setMansionId] = useState<string | null>(sessionStorage.getItem('mansionId'));
  const [residentId, setResidentId] = useState<string | null>(sessionStorage.getItem('residentId'));

  let isVendorLogin = sessionStorage.getItem('login') === 'vendor' ? true : false;
  let isResidentLogin = sessionStorage.getItem('login') === 'resident' ? true : false;
  useEffect(() => {  
    isVendorLogin = sessionStorage.getItem('login') === 'vendor' ? true : false;
    isResidentLogin = sessionStorage.getItem('login') === 'resident' ? true : false;
    console.log(isVendorLogin);
    console.log(isResidentLogin);
  }, [window.location]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newAccountId = sessionStorage.getItem('accountId');
      const newMansionId = sessionStorage.getItem('mansionId');
      const newResidentId = sessionStorage.getItem('residentId');
  
      if (newAccountId !== accountId || newMansionId !== mansionId || newResidentId !== residentId) {
        setAccountId(newAccountId);
        setMansionId(newMansionId);
        setResidentId(newResidentId);
      }
    }, 1000); // 1秒間隔でチェック
    return () => clearInterval(intervalId);
  }, [accountId, mansionId, residentId]); // 各state変数を依存配列に指定
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      // /signage を含むパスかどうかをチェック
      if (window.location.pathname.includes('/signage')) {
        window.location.reload(); // ページをリロード
      }
    }, 5 * 60 * 1000); // 5分

    return () => clearInterval(intervalId);
  }, []); // 空の依存配列でマウント時にのみ実行
  
  return (
    <ThemeProvider theme={theme}>
      <LoadingModalProvider>
        <LoadingModal />
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<VendorLogin />} />
            <Route path="/vendor/login" element={<VendorLogin />} />
            <Route path="/resident/login" element={<ResidentLogin />} />

            {/* 認証が必要なページは、Navigateで囲む */}
            <Route path="/vendor/dashboard" element={isVendorLogin ? <VendorDashboard /> : <></>} />
            <Route path="/vendor/management" element={isVendorLogin ? <VendorManagement /> : <></>} />
            <Route path="/resident/mypage" element={isResidentLogin ? <ResidentMypage /> : <></>} />

            <Route path="/signage/1" element={<SignageTemplate1 />} />
            <Route path="/signage/2" element={<SignageTemplate2 />} />
            <Route path="/signage/3" element={<SignageTemplate3 />} />
            <Route path="/signage/4" element={<SignageTemplate4 />} />
            <Route path="/signage/5" element={<SignageTemplate5 />} />
            {/*... その他のサイネージ画面 */}
          </Routes>
        </BrowserRouter>
      </LoadingModalProvider>
    </ThemeProvider>
  );
};

export default App;