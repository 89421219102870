import React, { useRef, useState, useEffect } from 'react';

import {
    Typography,
    Button,
    Grid,
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import './pages/VendorManagement/VendorManagement.scss';

import { useLoadingModal } from './../../common/LoadingModalContext'; //ローディング画面

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PostAddIcon from '@mui/icons-material/PostAdd'; // 掲載アイコン
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // アンケートアイコン
import SettingsIcon from '@mui/icons-material/Settings'; // 設定アイコン
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; // 右矢印アイコン
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'; //住民管理アイコン

import { updateSelectTemplate } from '../../../api/update';
import { getMansions } from '../../../api/datas';
import { Preview } from '@mui/icons-material';

interface ListingsManagementProps {
    handleMenuClick: (menu: string) => void; // handleMenuClick の型を修正
    selectedMansion: any;
}

const ManagementHome: React.FC<ListingsManagementProps> = ({ handleMenuClick, selectedMansion }) => {
    const { showLoadingModal, hideLoadingModal } = useLoadingModal(); //ローディングの表示非表示
    const sliderRef = useRef<Slider>(null); // Slider コンポーネントへの参照
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const slideChangeHandler = (index: number) => {
        setCurrentSlideIndex(index);
        sliderRef.current?.slickGoTo(index)
    };
    // カルーセルの設定
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        fade: true,
        appendDots: (dots: any) => {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 10, mb: 2, position: 'absolute', top: 5, left: 0, width: '100%', height: '5vh' }}>
                    <Button variant="text" color="inherit" style={{ borderRadius: 0, borderBottom: currentSlideIndex == 0 ? '3px solid #8ecde2' : 'none' }} onClick={() => slideChangeHandler(0)}><Typography>{"住民向け"}</Typography></Button>
                    <Button variant="text" color="inherit" style={{ borderRadius: 0, borderBottom: currentSlideIndex == 1 ? '3px solid #8ecde2' : 'none' }} onClick={() => slideChangeHandler(1)}><Typography>{"業者向け"}</Typography></Button>
                </Box>
            )

        }
    };
    const fetchSelectTemplateUpdate = async () => {
        showLoadingModal();
        let value = currentTemplate;

        console.log(value)
        //変更がない場合は処理しない
        if (selectedMansion?.template === value) {
            hideLoadingModal();
            console.error(`変更なし,<span class="math-inline">\{selectedMansion?\.templates?\.forResident\},</span>{value}`)
            return;
        }
        if (value === null) {
            hideLoadingModal();
            console.error("valueが取得できませんでした")
            return;
        }

        const accountId = sessionStorage.getItem('accountId');
        const mansionId = selectedMansion?.id;
        if (!accountId || !mansionId) {
            hideLoadingModal();
            console.error("Idが取得できませんでした")
            return;
        }
        try {
            updateSelectTemplate(accountId, mansionId, value);
        } catch (e) {
            console.log(e)
        } finally {
            setOpenDialogOfChangeTemplate(false);
            getMansions();
            hideLoadingModal();
        }
    }
    const [currentTemplate, setCurrentTemplate] = useState<string | null>(selectedMansion?.template);
    const templateChangeHandler = async (value: string) => {
        await setCurrentTemplate(value);
        console.log(selectedMansion)
        setOpenDialogOfChangeTemplate(true);
    };
    const [urlParam, setUrlParam] = useState<string>('');
    useEffect(() => {
        setCurrentTemplate(selectedMansion?.template);
        const accountId = sessionStorage.getItem('accountId'); // アカウントIDを取得
        const mansionId = selectedMansion?.id;
        setUrlParam(`?accountId=${accountId}&mansionId=${mansionId}`);
    }, [selectedMansion]);

    const templatesForResidents = [
        { label: "テンプレート１", navigate: "/signage/4/", value: "4", previewUrl: "https://storage.googleapis.com/sumasai.appspot.com/Common/Template4.png" },
        { label: "テンプレート２", navigate: "/signage/5/", value: "5", previewUrl: "https://storage.googleapis.com/sumasai.appspot.com/Common/Template5.png" },
    ];
    const templatesForBusiness = [
        { label: "テンプレート１", navigate: "/signage/1/", value: "1", previewUrl: "https://storage.googleapis.com/sumasai.appspot.com/Common/Template1.png" },
        { label: "テンプレート２", navigate: "/signage/2/", value: "2", previewUrl: "https://storage.googleapis.com/sumasai.appspot.com/Common/Template2.png" },
        { label: "テンプレート３", navigate: "/signage/3/", value: "3", previewUrl: "https://storage.googleapis.com/sumasai.appspot.com/Common/Template3.png" },
    ];
    const [openDialogOfChangeTemplate, setOpenDialogOfChangeTemplate] = useState<boolean>(false)

    return (
        <>
            <Slider className='vendor-management__slider' {...settings} ref={sliderRef} >
                <div className='vendor-management__slider__content' key={"Slide-1"}>
                    {templatesForResidents.map((item) => (
                        <Box key={`box-${item.label}`} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', }}>
                            <IconButton
                                color="inherit"
                                onClick={() => templateChangeHandler(item.value)}
                            >
                                {currentTemplate === item.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            </IconButton>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { window.open(item.navigate + urlParam); }}
                                key={item.label}
                            >
                                {window.screen.width > 798 ? item.label : item.label.replace('テンプレート', '')}
                            </Button>
                        </Box>
                    ))}
                </div>
                <div className='vendor-management__slider__content' key={"Slide-2"}>
                    {templatesForBusiness.map((item) => (
                        <Box key={`box-${item.label}`} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', }}>
                            <IconButton
                                color="inherit"
                                onClick={() => templateChangeHandler(item.value)}
                            >
                                {currentTemplate === item.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            </IconButton>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { window.open(item.navigate + urlParam); }}
                                key={item.label}
                            >
                                {window.screen.width > 798 ? item.label : item.label.replace('テンプレート', '')}
                            </Button>
                        </Box>
                    ))}
                </div>
            </Slider>
            <Grid container spacing={2} sx={{ width: '100%', mt: 3, justifyContent: 'space-evenly' }}>
                <Grid item>
                    <Button
                        variant='outlined'
                        color="primary"
                        aria-label="掲載"
                        onClick={() => handleMenuClick('listings')} // 掲載をクリックしたときの処理
                        className='vendor-management__button'
                    >
                        {
                            window.screen.width > 798 ?
                                (
                                    <>
                                        <Typography variant="h4">掲載</Typography>
                                        <PostAddIcon sx={{ fontSize: 150 }} />
                                    </>
                                )
                                :
                                (
                                    <PostAddIcon sx={{ fontSize: 50 }} />
                                )
                        }
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='outlined'
                        color="primary"
                        aria-label="アンケート"
                        onClick={() => handleMenuClick('questionnaire')} // アンケートをクリックしたときの処理
                        className='vendor-management__button'
                    >
                        {
                            window.screen.width > 798 ?
                                (
                                    <>
                                        <Typography variant="h4">アンケート</Typography>
                                        <QuestionAnswerIcon sx={{ fontSize: 150 }} />
                                    </>
                                ) :
                                (
                                    <QuestionAnswerIcon sx={{ fontSize: 50 }} />
                                )
                        }
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='outlined'
                        color="primary"
                        aria-label="設定"
                        onClick={() => handleMenuClick('settings')} // 設定をクリックしたときの処理
                        className='vendor-management__button'
                    >
                        {
                            window.screen.width > 798 ?
                                (
                                    <>
                                        <Typography variant="h4">設定</Typography>
                                        <SettingsIcon sx={{ fontSize: 150 }} />
                                    </>
                                ) :
                                (
                                    <SettingsIcon sx={{ fontSize: 50 }} />
                                )
                        }

                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ width: '100%', mt: 3, justifyContent: 'space-evenly' }}>
                <Grid item>
                    <Button
                        variant='outlined'
                        color="primary"
                        aria-label="編集"
                        onClick={() => handleMenuClick('topImages')} // 編集をクリックしたときの処理
                        className='vendor-management__button__Main'
                        sx={{ height: 'fit-content' }}
                    >
                        {
                            window.screen.width > 798 ?
                                (
                                    <>
                                        <Typography variant='h4'>TOP画像を編集</Typography>
                                        <Typography>TOP画像のアップロードや<br />編集はこちらから</Typography>
                                        <KeyboardArrowRightIcon sx={{ fontSize: 100 }} />
                                    </>
                                )
                                :
                                (
                                    <Typography variant='body1'>TOP画像を編集</Typography>
                                )
                        }
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='outlined'
                        color="primary"
                        aria-label="住民"
                        onClick={() => handleMenuClick('residents')} // 住民をクリックしたときの処理
                        className='vendor-management__button__Sub'
                        sx={{ height: 'fit-content' }}
                    >
                        {
                            window.screen.width > 798 ?
                                <ManageAccountsIcon sx={{ fontSize: 100 }} />
                                :
                                <ManageAccountsIcon sx={{ fontSize: 50 }} />
                        }
                    </Button>
                </Grid>
            </Grid>
            {/**テンプレート変更の確認ダイアログ */}
            <Dialog open={openDialogOfChangeTemplate} onClose={() => { setOpenDialogOfChangeTemplate(false) }}>
                <DialogTitle>プレビュー</DialogTitle>
                <DialogContent>
                    <img
                        src={
                            currentSlideIndex === 0 ?
                                templatesForResidents.find(item => item.value === currentTemplate)?.previewUrl :
                                templatesForBusiness.find(item => item.value === currentTemplate)?.previewUrl
                        }
                        alt="preview"
                        style={{ width: '100%' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => { setOpenDialogOfChangeTemplate(false) }}>キャンセル</Button>
                    <Button onClick={() => { fetchSelectTemplateUpdate() }}>適用する</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ManagementHome;