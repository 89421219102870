// src/api/update.ts
import * as api from './api'; // api.ts をインポート
import { getMansions } from './datas';

export const updatePublication = async (
  id: string,
  accountId: string,
  mansionId: string,
  category: string,
  subcategory: string,
  title: string,
  details: string,
  attachmentUrls: string[],
  startDate: string,
  endDate: string,
) => {
  try {
    const response = await fetch(api.updatePublishEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`, { // auth.ts からエンドポイントを取得
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        category,
        subcategory,
        title,
        details,
        attachmentUrls,
        startDate,
        endDate,
      }),
    });
  } catch (error) {
    console.error('Error updating publication:', error);
    throw error;
  }
};

export const updateSelectTemplate = async(
  accountId: string,
  mansionId: string,
  template: string
)=> {
  try {
    console.log(template);
    const response = await fetch(api.updateSelectTemplateEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({template}),
    });
    if(response.ok) {
      //マンションデータの再読み込み
      await getMansions();
    }
  } catch (error) {
    console.error('Error updating template:', error);
    throw error;
  }
}

export const updateMansionData = async(
  name: string, 
  address:string, 
  contact1:string, 
  contact1_tel:string, 
  contact2:string, 
  contact2_tel:string, 
  themeColor:string
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.updateMansionDataEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        address,
        contact1,
        contact1_tel,
        contact2,
        contact2_tel,
        themeColor
      })
    });
    if(response.ok) {
      //マンションデータの再読み込み
      await getMansions();
    }
  } catch (error) {
    console.error('Error updating mansion data:', error);
    throw error;
  }
}

export const updateResidentAccount = async(
  id: string,
  name: string,
  roomNumber: string,
  email: string,
  password: string
) => {
  const accountId = sessionStorage.getItem("accountId");
  try {
    const response = await fetch(api.updateResidentAccountEndpoint+`?accountId=${accountId}`,{
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        name,
        roomNumber,
        email,
        password
      })
    });
  } catch (error) {
    console.error('Error updating resident account:', error);
    throw error;
  }
}

export const updateResidentGroup = async(
  id: string,
  name: string,
  description: string,
  members: string[]
) => {
  const accountId = sessionStorage.getItem("accountId");
  try {
    const response = await fetch(api.updateResidentGroupEndpoint+`?accountId=${accountId}`,{
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        name,
        description,
        members
      })
    });
  } catch (error) {
    console.error('Error updating resident group:', error);
    throw error;
  }
}


export const updateMainImageIndex = async(
  updateItems:any
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.updateMainImageIndexEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`,{
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateItems)
    });
  } catch (error) {
    console.error('Error updating main image index:', error);
    throw error;
  }
}

export const updateQuestionnaire =async(
  questionnaire:any,
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  const id = questionnaire.id;
  const title = questionnaire.title;
  const startDate = questionnaire.startDate;
  const endDate = questionnaire.endDate;
  const questions = questionnaire.questions;
  const memberIds = questionnaire.non_respondent;
  const attachmentUrls = questionnaire.attachmentUrls;
  try {
    const response = await fetch(api.updateQuestionnaireEndpoint,{
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        accountId,
        mansionId,
        title,
        startDate,
        endDate,
        questions,
        memberIds,
        attachmentUrls
      })
    });
  } catch (error) {
    console.error('Error updating questionnaire:', error);
    throw error;
  }
}
