import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  Button
} from '@mui/material';

//Icons
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


//style 
const maruBatsuStyle = {
  width: '10vw',
  AspectRatio: '1:1',
  border:'1px solid #ccc',
  borderRadius: '10px',
}

interface QuestionProps {
  question: {
    id: string;
    text: string;
    type: 'text' | 'number' | 'dropdown' | 'checkbox' | 'date' | 'multiple-date' | 'maru-batsu';
    options?: string[];
    min?: number;
    max?: number;
    startDate?: string;
    endDate?: string;
    // multiple-date タイプ用
    dateRanges?: { startDate: string; endDate: string }[];
  };
  onQuestionChange?: (questionId: string, updatedQuestion: QuestionProps['question']) => void;
  answer: any;
  onAnswerChange: (questionId: string, answer: any) => void;
  editable: boolean;
  isPreview?: boolean; // プレビューモードかどうか
  isEditing?: boolean; // 編集モードかどうか
}

const Question: React.FC<QuestionProps> = ({ question, onQuestionChange, answer, editable, onAnswerChange, isPreview, isEditing }) => {
  const [editingQuestion, setEditingQuestion] = useState(question);
  const handleChange = (event: any) => {
    onAnswerChange(question.id, event.target.value);
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    onAnswerChange(question.id, { ...answer, [name]: checked });
  };

  const handleQuestionTextEdit = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newQuestion = { ...editingQuestion, text: event.target.value };
    setEditingQuestion(newQuestion);
    onQuestionChange!(question.id, newQuestion); // onQuestionChange を呼び出す
  };

  const handleNumberRangeEdit = (field: 'min' | 'max', value: number) => {
    const newQuestion = { ...editingQuestion, [field]: value };
    setEditingQuestion(newQuestion);
    onQuestionChange!(question.id, newQuestion); // onQuestionChange を呼び出す
  };

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...(editingQuestion.options || [])];
    newOptions[index] = value;
    const newQuestion = { ...editingQuestion, options: newOptions };
    setEditingQuestion(newQuestion);
    onQuestionChange?.(question.id, newQuestion);
  };

  const handleAddOption = () => {
    const newQuestion = { ...editingQuestion, options: [...(editingQuestion.options || []), ''] };
    setEditingQuestion(newQuestion);
    onQuestionChange?.(question.id, newQuestion);
  };

  const handleAddDateRange = () => {
    const newDateRanges = [...(editingQuestion.dateRanges || []), { startDate: '', endDate: '' }];
    const newQuestion = { ...editingQuestion, dateRanges: newDateRanges };
    setEditingQuestion(newQuestion);
    onQuestionChange?.(question.id, newQuestion);
  };

  const handleMultipleDateRangeChange = (index: number, field: 'startDate' | 'endDate', value: string) => {
    const newDateRanges = [...(editingQuestion.dateRanges || [])];
    newDateRanges[index] = { ...newDateRanges[index], [field]: value };
    const newQuestion = { ...editingQuestion, dateRanges: newDateRanges };
    setEditingQuestion(newQuestion);
    onQuestionChange?.(question.id, newQuestion);
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...(editingQuestion.options || [])];
    newOptions.splice(index, 1);
    const newQuestion = { ...editingQuestion, options: newOptions };
    setEditingQuestion(newQuestion);
    onQuestionChange?.(question.id, newQuestion);
  };

  if (isEditing) {
    // 編集モード
    return (
      <Box sx={{ border: '1px solid #ccc', p: 2, mb: 2 }}>
        {/* 質問文の設定 */}
        <TextField
          label="質問文"
          value={editingQuestion.text}
          onChange={(event) => { handleQuestionTextEdit(event) }}
          fullWidth
          multiline
          margin="normal"
        />

        {/* 各種設定 */}
        {editingQuestion.type === 'number' && (
          <Box>
            <TextField
              label="最小値"
              type="number"
              value={editingQuestion.min || ''}
              onChange={(event) => { handleNumberRangeEdit('min', Number(event.target.value)) }}
              margin="normal"
            />
            <TextField
              label="最大値"
              type="number"
              value={editingQuestion.max || ''}
              onChange={(event) => { handleNumberRangeEdit('max', Number(event.target.value)) }}
              margin="normal"
            />
          </Box>
        )}

        {editingQuestion.type === 'dropdown' && (
          <Box>
            <Typography variant="subtitle2">選択肢</Typography>
            {(editingQuestion.options || []).map((option, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  fullWidth
                />
                <IconButton onClick={() => handleRemoveOption(index)} size="small">
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={handleAddOption} variant="outlined" size="small">
              選択肢を追加
            </Button>
          </Box>
        )}
        {editingQuestion.type === 'checkbox' && (
          <Box>
            <Typography variant="subtitle2">選択肢</Typography>
            {(editingQuestion.options || []).map((option, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  fullWidth
                />
                <IconButton onClick={() => handleRemoveOption(index)} size="small">
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={handleAddOption} variant="outlined" size="small">
              選択肢を追加
            </Button>
          </Box>
        )}
        {editingQuestion.type === 'multiple-date' && (
          <Box>
            <Typography variant="subtitle2">日付範囲</Typography>
            {(editingQuestion.dateRanges || []).map((range, index) => (
              <Box key={index} sx={{ mb: 1 }}>
                <Typography variant="body2">第{index + 1}希望日</Typography>
                <TextField
                  label="選択可能な開始日"
                  type="date"
                  value={range.startDate || ''}
                  onChange={(e) => handleMultipleDateRangeChange(index, 'startDate', e.target.value)}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="選択可能な終了日"
                  type="date"
                  value={range.endDate || ''}
                  onChange={(e) => handleMultipleDateRangeChange(index, 'endDate', e.target.value)}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            ))}
            <Button onClick={handleAddDateRange} variant="outlined" size="small">
              選択肢を追加
            </Button>
          </Box>
        )}
        {editingQuestion.type === 'maru-batsu' && (
          <FormGroup row sx={{ display: 'flex', gap:5}}>
            <IconButton style={maruBatsuStyle}> <PanoramaFishEyeIcon sx={{width:'80%',height:'80%'}}/></IconButton>
            <IconButton style={maruBatsuStyle}><CloseIcon  sx={{width:'80%',height:'80%'}}/></IconButton>
          </FormGroup>
        )}
      </Box>
    );
  }
  if (isPreview) {
    // プレビューモードの場合
    return (
      <FormControl fullWidth margin="normal">
        <Typography variant="subtitle1">{question.text}</Typography>
        {question.type === 'dropdown' && (
          <Typography variant="body2">選択肢: {question.options?.join(', ')}</Typography>
        )}
        {question.type === 'date' && (
          <Typography variant="body2">
            日付範囲: {question.startDate} から {question.endDate}
          </Typography>
        )}
        {question.type === 'number' && (
          <Typography variant="body2">
            数値範囲: {question.min} から {question.max}
          </Typography>
        )}
        {question.type === 'checkbox' && (
          <Typography variant="body2">選択肢: {question.options?.join(', ')}</Typography>
        )}
        {question.type === 'multiple-date' && (
          <div>
            {question.dateRanges?.map((range, index) => (
              <Typography key={index} variant="body2">
                第{index + 1}希望日: {range.startDate} から {range.endDate}
              </Typography>
            ))}
          </div>
        )}
        {question.type === 'maru-batsu' && (
          <Typography variant="body2">選択肢: マル, バツ</Typography>
        )}
        {/* text typeの場合は特に表示するオプションがないので省略 */}
      </FormControl>
    );
  }

  // 通常モードの場合 (既存のswitch文)
  switch (question.type) {
    case 'text':
      return (
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <TextField
            value={answer || ''}
            onChange={editable ? handleChange : undefined}
            fullWidth
            margin="normal"
            disabled={!editable}
          />
        </FormControl>
      );
    case 'number':
      return (
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <TextField
            type="number"
            value={answer || ''}
            onChange={editable ? handleChange : undefined}
            fullWidth
            margin="normal"
            inputProps={{ min: question.min, max: question.max }}
            disabled={!editable}
          />
        </FormControl>
      );
    case 'dropdown':
      return (
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <Select
            labelId={`question-${question.id}-label`}
            value={answer || ''}
            onChange={editable ? handleChange : undefined}
            disabled={!editable}
          >
            {question.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'checkbox':
      return (
        <FormControl component="fieldset" margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <FormGroup>
            {question.options?.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    name={option}
                    checked={answer.find((item: any) => item === option)}
                    onChange={handleCheckboxChange}
                    disabled={!editable}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
    case 'date':
      return (
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <TextField
            type="date"
            value={answer || ''}
            onChange={editable ? handleChange : undefined}
            fullWidth
            margin="normal"
            disabled={!editable}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      );
    case 'multiple-date':
      return (
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          {question.dateRanges?.map((range, index) => (
            <TextField
              key={index}
              type="date"
              label={`第${index + 1}希望日`}
              value={answer?.[index] || ''}
              onChange={(e) => {
                const newAnswer = [...(answer || [])];
                newAnswer[index] = e.target.value;
                onAnswerChange(question.id, newAnswer);
              }}
              fullWidth
              margin="normal"
              disabled={!editable}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: range.startDate,
                max: range.endDate
              }}
            />
          ))}
        </FormControl>
      );
    case 'maru-batsu':
      return (
        <FormControl component="fieldset" margin="normal">
          <Typography variant="subtitle1">{question.text}</Typography>
          <FormGroup row>
            <IconButton
              color={answer === 'マル' ? 'primary' : 'default'} // 選択状態によって色を変える
              onClick={() => onAnswerChange(question.id, 'マル')}
              disabled={!editable}
              style={maruBatsuStyle}
            >
              <PanoramaFishEyeIcon sx={{width:'80%',height:'80%'}} />
            </IconButton>
            <IconButton
              color={answer === 'バツ' ? 'primary' : 'default'}
              onClick={() => onAnswerChange(question.id, 'バツ')}
              disabled={!editable}
              style={maruBatsuStyle}
            >
              <CloseIcon  sx={{width:'80%',height:'80%'}}/>
            </IconButton>
          </FormGroup>
        </FormControl>
      );
    default:
      return null;
  }
};

export default Question;