import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//mui
import { Typography, Box} from '@mui/material';

const WeatherForecast: React.FC<{ mansionData: { address: string } }> = ({ mansionData }) => {
  const [weatherData, setWeatherData] = useState<any[]>([]); // 今日と明日の天気データを配列で保持

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const OPENWEATHER_API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
        const GEOCODING_API_KEY = process.env.REACT_APP_GEOCODING_API_KEY;
        // GCP Geocoding API で住所から緯度経度を取得
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${mansionData.address}&key=${GEOCODING_API_KEY}`;
        const geocodingResponse = await fetch(geocodingUrl);
        const geocodingData = await geocodingResponse.json();
        const location = geocodingData.results[0].geometry.location;
        const { lat, lng } = location;

        // OpenWeatherAPI で今日の天気予報を取得
        const weatherUrlToday = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${OPENWEATHER_API_KEY}&units=metric&lang=ja`;
        const weatherResponseToday = await fetch(weatherUrlToday);
        const weatherDataToday = await weatherResponseToday.json();

        // OpenWeatherAPI で明日の天気予報を取得
        const weatherUrlTomorrow = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=${OPENWEATHER_API_KEY}&units=metric&lang=ja`;
        const weatherResponseTomorrow = await fetch(weatherUrlTomorrow);
        const weatherDataTomorrow = await weatherResponseTomorrow.json();
        // 明日の正午の天気予報を取得
        const tomorrowNoon = weatherDataTomorrow.list.find((item: any) => {
          const [date] = item.dt_txt.split(' ');
          return new Date(date).toDateString() === new Date(Date.now() + 24 * 60 * 60 * 1000).toDateString() && item.dt_txt.includes('12:00:00');
        });

        setWeatherData([weatherDataToday, tomorrowNoon]);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };
    if(mansionData.address){
      fetchWeatherData();
    }
  }, [mansionData.address]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div style={{ textAlign: 'center', width: '100%',marginTop:'-2.5vh'}}>
    {weatherData.length === 0 && <p>Loading...</p>}
      <Slider {...settings}>
        {weatherData.map((data, index) => (
          <div key={index}>
            {data && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',ml: 'auto' ,justifyContent: 'center', gap: 1 }}>
                <img src={`http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`} alt={data.weather[0].description} style={{width:'50px',height:'50px'}}/> {/* 天気アイコン */}
                <Box>
                  <Typography variant="body1">{data.main.temp}℃</Typography> {/* 気温と天気 */}
                  <Typography variant="body2">{data.weather[0].description}</Typography> 
                </Box>
              </Box>
            )}
            {index === 0 && <Typography variant='body2'>設置場所の天気（今日）</Typography>} {/* 今日と明日のラベル */}
            {index === 1 && <Typography variant='body2'>設置場所の天気（明日）</Typography>}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WeatherForecast;