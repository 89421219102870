// src/api/auth.ts
//ログイン
export const loginEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/Login'; 
export const residentLoginEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/residentLogin'

//データ取得
export const getMansionsEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/GetMansions'; // 追加
export const getMansionDataEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/GetMansionData'
export const getTopImages = 'https://asia-northeast2-sumasai.cloudfunctions.net/getMainImage';
export const getMyQuestionnaireEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/getMyQuestionnaires'
export const getQuestionnairesEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/getQuestionnaires'; // 追加
export const getPublicationsByCategoryEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/getPublishBycategory';
export const getResidentAccountsEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/getResidentAccounts';
export const getResidentGroupsEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/getResidentGroups'
export const getFileURLEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/GetFileURL'

//作成
export const createPublishEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/createPublish';
export const createSubCategoryEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/createSubCategory';
export const createMainImageEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/addMainImage';
export const createQuestionnaireEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/createQuestion'
export const postAnswerEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/postAnswer'
export const createResidentAccountEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/createResidentAccount'
export const createResidentGroupEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/createResidentGroup'

//更新
export const updatePublishEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updatePublish';
export const updateSelectTemplateEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateTemplateSelect';
export const updateMansionDataEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateMansionData';
export const updateResidentAccountEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateResidentAccount';
export const updateResidentGroupEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateResidentGroup';
export const updateMainImageIndexEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateMainImageIndex';
export const updateQuestionnaireEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/updateQuestionnaires'

//削除
export const deleteSubCategoryEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deleteSubCategory';
export const deletePublishEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deletePublish';
export const deleteResidentAccountEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deleteResidentAccount';
export const deleteResidentGroupEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deleteResidentGroup';
export const deleteMainImageEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deleteMainImage';
export const deleteQuestionnaireEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/deleteQuestionnaire'

//ダウンロード
export const downloadQuestionnaireEndpoint = 'https://asia-northeast2-sumasai.cloudfunctions.net/downloadCSV'