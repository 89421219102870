import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
} from '@mui/material';
import { getFileUrl } from '../../../../api/datas';


interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationProps {
    newsData: Publication[];
}
const extractFilename = (url:string) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const encodedFilename = pathname.substring(pathname.lastIndexOf('/') + 1);
      const decodedFilename = decodeURIComponent(encodedFilename);
      const filename = decodedFilename.split('?')[0]; // クエリパラメータを除去
      return filename;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }
  
const NewPublication: React.FC<PublicationProps> = ({ newsData }) => {

    return (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
                height: '95%',
                mt:-2.5,
                ml:2,
                position: 'relative'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h6">新着情報</Typography>
            </Box>
            <Divider sx={{ border: '1px solid black', width: '60%' }} />
            {newsData === null && <p>Loading...</p>}
            <List sx={{width:'100%'}}>
                {newsData?.map((data, index) => (
                    <ListItem 
                    key={index} 
                    disablePadding 
                    sx={{
                        height:'15%',
                        width:'90%',
                        border:'1px solid #5c5c5b',
                        borderLeft:'20px solid #5c5c5b',
                        borderRadius:'10px',
                        mb:0.5,
                        overflow:'hidden'
                        }}>
                        <ListItemButton 
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'flex-start',
                            height:'100%',
                            width:'100%',
                            p:0,
                            pl:1
                            }}>
                                <ListItemText
                                    sx={{p:0}}
                                    primary={<Typography variant="body1" noWrap>{data.title||'タイトルなし'}</Typography>}
                                    secondary={<Typography variant="body2" noWrap>{data.details||' 本文なし'}</Typography>}
                                >

                                </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default NewPublication;