import React, { useState, useEffect } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Typography,
  AppBar,
  styled,
  Toolbar,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Collapse,
} from '@mui/material';

import {
  ExpandLess,
  ExpandMore,
  StarBorder,
} from '@mui/icons-material';

import { getMansions,getQuestionnaires } from '../../../../../api/datas'; // getMansions 関数をインポート

import './VendorDashboard.scss';
import AnswerListDialog from '../../../../common/AnswerListDialog'; // AnswerListDialog をインポート

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  backgroundColor: 'gray',
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 128,
  },
}));


const VendorDashboard: React.FC = () => {
  const accountId = sessionStorage.getItem('accountId'); // アカウントIDを取得
  const navigate = useNavigate();

  const [mansions, setMansions] = useState<any[]>([]); // マンションデータ
  const [mansionId, setMansionId] = useState<any | null>(''); // 選択されたマンションID
  const [open, setOpen] = useState(false); // アンケート一覧の開閉状態

  const [questionnaires, setQuestionnaires] = useState<any[]>([]); // アンケートデータ
  const [dialogOpen, setDialogOpen] = useState(false); // ダイアログの開閉状態
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<any | null>(null); // 選択されたアンケートID

  const handleOpenDialog = (questionnaire: any) => {
    setSelectedQuestionnaire(questionnaire);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchMansions = async () => {
      try {
        const mansionData = await getMansions(); // getMansions 関数を呼び出す
        setMansions(mansionData);
        console.log(mansionData);
      } catch (error) {
        console.error('Error fetching mansions:', error);
        // エラーメッセージをユーザーに表示するなど、適切な処理を追加
      }
    };

    fetchMansions();
  }, [accountId]);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const questionnairesData = await getQuestionnaires(); // getQuestionnaires 関数を呼び出す
        setQuestionnaires(questionnairesData);
        console.log(questionnairesData);
      } catch (error) {
        console.error('Error fetching questionnaires:', error);
        // エラーメッセージをユーザーに表示するなど、適切な処理を追加
      }
    };

    fetchQuestionnaires(); 
  }, [mansionId, accountId]); 

  const handleLogout = () => {
    // TODO: ログアウト処理を実装
    navigate('/vendor/login');
  };
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{backgroundColor: 'whitesmoke', width: '100vw', height: '100vh'}}>
    <AppBar position="static">
      <StyledToolbar>
        <Grid direction={'column'} style={{width: '50%', display: 'flex', justifyContent: 'space-between', alignContent: 'flex-start'}} container spacing={2} alignItems="center">
        <Grid item xs={12} style={{width: '100%'}}>
          <FormControl fullWidth style={{width: '100%'}}>
              <Select
                className="white-select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mansionId}
                onChange={(e) => {
                  // 選択されたマンションIDを元に、データを取得する処理を追加
                  console.log(e.target.value);
                  setMansionId(e.target.value);
                  sessionStorage.setItem('mansionId', e.target.value);
                }}
              >
                {mansions && mansions.map((mansion) => (
                  <MenuItem key={mansion.id} value={mansion.id}>
                    {mansion.name}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{width: '100%'}}>
          <Typography style={{textAlign:"left"}}>
            {accountId}
          </Typography>
        </Grid>  
        </Grid>
      </StyledToolbar>
    </AppBar>
    <Container style={{ marginTop: 4, marginBottom: 4 ,display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <Button onClick={() => navigate('/vendor/management')} color='inherit' variant='contained' className='vendor-dashboard__button' style={{marginTop:'20px'}}>
        <Typography variant='h6'>管理画面を確認する</Typography>
      </Button>
        {/* 回答可能なアンケートの一覧 */}
        {mansionId &&
        <List color='inherit' style={{borderBottom: '1px solid gray', boxShadow: ' 0 4px 4px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px', width: '70%', margin: '20px auto'}} className='vendor-dashboard__button'>
          <ListItemButton onClick={handleClick}>
            <ListItemText primary="アンケート一覧" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse style={{height:'40vh',overflow:'scroll'}} in={open} timeout="auto" unmountOnExit>
            <List style={{maxHeight: '40vh', overflow: 'auto'}} component="div" disablePadding>
              {questionnaires && questionnaires.map((question) => (
                <>
                <ListItem key={question.id} sx={{ pl: 4 }}>
                  <ListItemText style={{width: '100%',textWrap: 'nowrap'}} primary={question.title} />
                  <ListItem style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Button variant="contained" sx={{backgroundColor: '#fa8f5a !important', color: 'white !important', ':hover': { backgroundColor: '#e47740 !important' }}} onClick={() => handleOpenDialog(question)}>回答一覧</Button>
                  </ListItem>
                </ListItem>
                <Divider />
                </>
              ))}
            </List>
          </Collapse>
        </List>
        }
      <Button style={{display: 'flex', justifyContent: 'center', margin:'auto', textDecoration: 'underline'}} variant="text" color="inherit" onClick={handleLogout}>
        ログアウトする
      </Button>
    </Container>
    {/* 回答一覧ダイアログ */}
    <AnswerListDialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        questionnaire={selectedQuestionnaire || ''} // アンケートIDを渡す
      />
    </div>
  );
};

export default VendorDashboard;