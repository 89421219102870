// src/api/datas.ts
import * as api from './api';

export const getMansionData = async() => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  if(accountId === null || mansionId === null) return;
  try {
    const response = await fetch(api.getMansionDataEndpoint + `?accountId=${accountId}&mansionId=${mansionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching mansion data:', error);
    throw error;
  }
}

export const getMansions = async () => {
  const accountId = sessionStorage.getItem("accountId");
  try {
    const response = await fetch(api.getMansionsEndpoint + `?accountId=${accountId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    const mansionsData = await response.json();
    sessionStorage.setItem("mansions", JSON.stringify(mansionsData)); // ローカルストレージに保存
    return mansionsData;
  } catch (error) {
    console.error('Error fetching mansions:', error);
    throw error;
  }
};

export const getTopImages = async() => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.getTopImages+`?accountId=${accountId}&mansionId=${mansionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching top images:', error);
    throw error;
  }
}

export const getMyQuestionnaire = async () => {  
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  const residentId = sessionStorage.getItem("residentId");
  if(accountId === null || mansionId === null || residentId === null) return;
  try {
    if(!accountId || !mansionId) throw new Error('accountId or mansionId is not found.')
    const response = await fetch(api.getMyQuestionnaireEndpoint+`?accountId=${accountId}&mansionId=${mansionId}&residentId=${residentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching my questionnaires:', error);
    throw error;
  }
};


export const getQuestionnaires = async () => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  if(accountId === null || mansionId === null) return;
  try {
    if(!accountId || !mansionId) throw new Error('accountId or mansionId is not found.')
    const response = await fetch(api.getQuestionnairesEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching questionnaires:', error);
    throw error;
  }
};

export const getPublicationsByCategory = async (selectedCategory: string) => { // 関数を追加
  try {
    const accountId = sessionStorage.getItem('accountId');
    const mansionId = sessionStorage.getItem('mansionId'); 
    const category = selectedCategory;

    if (accountId && mansionId && category) {
      const response = await fetch(api.getPublicationsByCategoryEndpoint+`?accountId=${accountId}&mansionId=${mansionId}&category=${category}`, { // auth.ts からエンドポイントを取得
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
      const publicationsData = await response.json(); // 結果を変数に保存
      console.log(publicationsData); // 変数を再利用
      sessionStorage.setItem('publications', JSON.stringify(publicationsData)); // ローカルストレージに保存
      return publicationsData; // 変数を再利用
    } else {
      throw new Error('accountId or mansionId is not found.'); // accountId または mansionId がない場合のエラー
    }
  } catch (error) {
    console.error('Error fetching publications:', error);
    throw error; 
  }
};

export const getResidentAccounts = async () => {
  try {
    const mansionId = sessionStorage.getItem('mansionId'); 
    if(mansionId) {
      const response = await fetch(api.getResidentAccountsEndpoint+`?mansionId=${mansionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
      const residentAccountsData = await response.json();
      sessionStorage.setItem('residentAccounts', JSON.stringify(residentAccountsData));
      return residentAccountsData;
    } else {
      throw new Error('mansionId is not found.');
    }
  } catch(error) {
    console.error('Error fetching resident accounts:', error);
    throw error;
  }
}

export const getResidentGroups = async () => {
  try {
    const mansionId = sessionStorage.getItem('mansionId'); 
    if(mansionId) {
      const response = await fetch(api.getResidentGroupsEndpoint+`?mansionId=${mansionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
      const residentGroupsData = await response.json();
      sessionStorage.setItem('residentAccounts', JSON.stringify(residentGroupsData));
      return residentGroupsData;
    } else {
      throw new Error('mansionId is not found.');
    }
  } catch(error) {
    console.error('Error fetching resident accounts:', error);
    throw error;
  }
}

export const getFileUrl = async(url: string) => {
  try {
    const response = await fetch(api.getFileURLEndpoint+`?filePath=${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching file url:', error);
    throw error;
  }
}