import React from 'react';
import { QRCodeSVG } from 'qrcode.react'; // Import QRCode as a named export
import { Stack, Box } from '@mui/material';

interface QRCodeProps {
  url1: string;
  url2?: string;
}

const QRCodeComponent: React.FC<QRCodeProps> = ({ url1, url2 }) => {
  return (
    <Stack direction="row" spacing={4} sx={{justifyContent:"center",height:'100%'}}>
      <Box sx={{p:2,height:'90%'}}> 
        <QRCodeSVG value={url1} level="M" style={{width:'100%',height:'100%'}}/> 
      </Box>
      {url2 &&
      <Box sx={{p:2,height:'90%'}}> 
        <QRCodeSVG value={url2} level="M" style={{width:'100%',height:'100%'}}/> 
      </Box>
      }
    </Stack>
  );
};

export default QRCodeComponent;