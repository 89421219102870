import React from 'react';

interface Props {
  fileUrl: string;
}

const ImageViewer: React.FC<Props> = ({ fileUrl }) => {
  return (
    <img src={fileUrl} alt="表示画像" style={{ maxWidth: '100%', maxHeight: '100%' }} />
  );
};

export default ImageViewer;