// src/api/client.ts
import * as api from './api';

export const login = async (accountId: string, password: string) => {
  try {
    const response = await fetch(api.loginEndpoint+`?accountId=${accountId}&password=${password}`, {
      method: 'POST', // POSTリクエストに変更
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage); // エラーをスロー
    }
    await sessionStorage.setItem("accountId", accountId);
    await sessionStorage.setItem("login", "vendor");
    console.log('ログイン成功')
    return await response.json();
  } catch (error) {
    console.error('Error logging in:', error);
    throw error; // エラーを上位に伝播させる
  }
};

export const residentLogin = async (residentId: string, password: string) => {
  try {
    const response = await fetch(api.residentLoginEndpoint+`?residentId=${residentId}&password=${password}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
    sessionStorage.setItem("residentId", residentId);
    const data = await response.json();
    await sessionStorage.setItem("mansionId", data.mansionId);
    await sessionStorage.setItem("accountId", data.accountId);
    await sessionStorage.setItem("residentData", JSON.stringify(data))
    await sessionStorage.setItem("login", "resident");
    return data;
  } catch (error) {
    console.error('Error resident logging in:', error);
    throw error;
  }

}