import React, { useState, useEffect, use } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router-dom';
import { getMansions } from '../../../../../api/datas';
import {
  Box,
  Button,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import logoIcon from '../../../../../assets/images/icons/logo.svg';

import './VendorManagement.scss';
import ManagementHome from '../../ManagementHome';
import ListingsManagement from '../../ListingsManagement';
import ListingDetails from '../../ListingDetail';
import Setting from '../../VendorSetting';
import ResidentManagement from '../../ResidentManagement';
import TopImageManagement from '../../TopImageManagement';
import QuestionnaireManagement from '../../QuestionnaireManagement';

const drawerWidth = 440;

const VendorManagement: React.FC = () => {
  const navigate = useNavigate();
  const accountId = sessionStorage.getItem('accountId'); // アカウントIDを取得
  const [mansions, setMansions] = useState<any[]>([]);
  const [filteredMansions, setFilteredMansions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<any | null>(null);  // 初期選択メニュー
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedMansion, setSelectedMansion] = useState<any | null>(null); // 選択されたマンション
  // AppBar 用のテーマ
  const appBarTheme = createTheme({
    palette: {
      primary: {
        main: selectedMansion?.themeColor || '#65a7e9', // selectedMansion があれば themeColor を、なければデフォルト色を使用
      },
    },
  });

  useEffect(() => {
    // 初期表示時に、メニューを選択状態にする
    const cachedMansions = sessionStorage.getItem("mansions"); // ローカルストレージからマンションデータを取得
    if (cachedMansions) {
      const parsedMansions = JSON.parse(cachedMansions);
      setMansions(parsedMansions);
      setFilteredMansions(parsedMansions);
    }
  }, [accountId]);

  useEffect(() => {
    const newMansions:any = sessionStorage.getItem('mansions');
    const currentMansionId = sessionStorage.getItem('mansionId');
    if (newMansions && Array.isArray(newMansions) && currentMansionId) {
      const foundMansion = newMansions?.find((mansion:any) => mansion.id === currentMansionId);
      if (foundMansion) {
        setSelectedMansion(foundMansion);
      }
    }
  }, [sessionStorage.getItem('mansions')]); // 依存配列に sessionStorage.getItem('mansions') を追加

  useEffect(() => {
    new Promise(() => {
      if(selectedMenu===''){
        getMansions().then((res)=>{
          setSelectedMansion(res);
          sessionStorage.setItem('mansions',JSON.stringify(res));
          const currentMansionId = sessionStorage.getItem('mansionId');
          if (currentMansionId) {
            const foundMansion = res?.find((mansion:any) => mansion.id === currentMansionId);
            if (foundMansion) {
              setSelectedMansion(foundMansion);
            }
          }
        })
      }
    })
  },[selectedMenu])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
    console.log('メニューを選択:', menu);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);

    const filtered = mansions.filter((mansion) =>
      mansion.name.includes(keyword)
    );
    setFilteredMansions(filtered);
  };

  // コンテンツをレンダリングする関数
  const renderContent = (menu:string) => {
    if (!selectedMansion) {
      return (
        <Typography variant="h5" align="center" sx={{ mt: 5 }}>
          編集するマンション名を選択してください
        </Typography>
      );
    }
    switch (menu) {
      case 'listings':
        return <ListingsManagement handleMenuClick={handleMenuClick}/>;
      case 'listingDetails': // 掲載情報の詳細を表示する case を追加
        return <ListingDetails handleMenuClick={handleMenuClick} />; // ListingDetails コンポーネントを表示
      case 'settings':
        return <Setting mansionData={selectedMansion} handleMenuClick={handleMenuClick}/>;
      case 'residents':
        return <ResidentManagement handleMenuClick={handleMenuClick} />;
      case 'topImages':
        return <TopImageManagement handleMenuClick={handleMenuClick}/>
      case 'questionnaire':
        return <QuestionnaireManagement handleMenuClick={handleMenuClick}/>;
      default:
        return <ManagementHome handleMenuClick={handleMenuClick} selectedMansion={selectedMansion} />;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={appBarTheme}>
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ width: '100%', textAlign: 'center',pl:2 }}
          >
            {selectedMansion?.name}
          </Typography>
          <Box sx={{ height:'5vh',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src={logoIcon} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="ロゴ"/>
            <IconButton color="inherit" onClick={() => navigate('/vendor/login')} >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        key={selectedMansion}
      >
        {/* マンション名検索 */}
        <TextField
          value={searchKeyword}
          onChange={handleSearchChange}
          placeholder="マンション名検索"
          style={{width: '90%',marginTop: '10px',marginLeft: 'auto',marginRight: 'auto'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ ml: 2 }} 
        />
        <Divider />
        <List>
          {filteredMansions && filteredMansions?.map((mansion) => ( 
            <ListItemButton key={mansion.id} onClick={() => {
              setSelectedMansion(mansion); // 選択されたマンションを更新
              sessionStorage.setItem('mansionId', mansion.id); // セッションストレージに保存
              handleDrawerClose(); // ドロワーを閉じる
              console.log('マンションを選択:', mansion.name);
            }}>
              <ListItemText primary={mansion.name} />
            </ListItemButton>
          ))}
        </List>
        <Button variant='text' color='inherit' onClick={handleDrawerClose} sx={{ position: 'absolute', bottom: 10, right: 10 }}>
          閉じる
        </Button>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar /> 
        {renderContent(selectedMenu)}
      </Box>
    </Box>
  );
};

export default VendorManagement;