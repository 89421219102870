const convertHalfWidthNonNumericToFullWidth = (str: string) => {
    if (!str) {
        return ''; // 空文字列やnull/undefinedの場合は空文字列を返す
    }

    return str
        .split('') // 文字列を文字の配列に分割
        .map((char) => {
            const charCode = char.charCodeAt(0);

            // 1. 半角数字 (0-9) の場合: そのまま返す
            //    ASCIIコード: 48 (0) から 57 (9)
            if (charCode >= 48 && charCode <= 57) {
                return char;
            }

            // 2. 半角スペースの場合: 全角スペースに変換して返す
            //    ASCIIコード: 32
            if (charCode === 32) {
                return '　'; // 全角スペース (U+3000)
            }

            // 3. その他の半角英数記号 (ASCII範囲 33-126) の場合: 全角に変換
            //    ASCIIコード: 33 (!) から 126 (~)
            //    この範囲の文字は、多くの場合 Unicode の U+FF01 から U+FF5E に対応する全角文字があります。
            //    その差分 (オフセット) は 0xFEE0 です (例: U+0021 (!) -> U+FF01 (！))
            if (charCode >= 33 && charCode <= 126) {
                const fullWidthCharCode = charCode + 0xFEE0;
                return String.fromCharCode(fullWidthCharCode);
            }

            // 4. 上記以外 (全角文字、制御文字など) の場合: そのまま返す
            return char;
        })
        .join(''); // 配列を文字列に結合
}

export default convertHalfWidthNonNumericToFullWidth;