import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import {
  Grid,
  AppBar,
  Typography,
  Box,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//apis
import { getMansionData } from '../../../api/datas';

import logoIcon from '../../../assets/images/icons/logo.svg'

//components
import CurrentTime from './components/CurrentTime';
import WeatherForecast from './components/WeatherForecast';
import NewQuestionnaireList from './components/NewQuestionnaireList';
import ContactInfo from './components/ContactInfo';
import QRCodeComponent from './components/QRCodeComponent';
import TopImage from './components/TopImage';
import PublicationList from './components/PublicationList';

//mobileComponents
import MobileCurrentTime from './componentsForMobile/CurrentTime';
import MobileWeatherForecast from './componentsForMobile/WeatherForecast';
import MobileNewQuestionnaireList from './componentsForMobile/NewQuestionnaireList';
import MobileContactInfo from './componentsForMobile/ContactInfo';
import MobileTopImage from './componentsForMobile/TopImage';
import MobilePublicationList from './componentsForMobile/PublicationList';

const SignageTemplate4: React.FC = () => {
  const [mansionData, setMansionData] = useState<any>([]);

  const fetchMansionData = async () => {
    if(sessionStorage.getItem('mansionId') === null){
      setTimeout(() => {
          fetchMansionData();
        }, 1000)
    }
    const data = await getMansionData();
    setMansionData(data);
  }
  useEffect(() => {
    //URLパラメータからAccountIdとMansionIDを取得
    const searchParams = window.location.search;
    if (!searchParams) return;
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get('accountId');
    const mansionId = urlParams.get('mansionId');
    if (accountId && mansionId) {
      sessionStorage.setItem('accountId', accountId);
      sessionStorage.setItem('mansionId', mansionId);
      try {
        fetchMansionData();
      } catch (error) {
        console.log(error);
      }
    }
    return;
  }
    , [window.location.search]);
  // AppBar 用のテーマ
  const appBarTheme = createTheme({
    palette: {
      primary: {
        main: mansionData?.themeColor || '#65a7e9', // selectedMansion があれば themeColor を、なければデフォルト色を使用
      },
    },
  });
  return (
    <div>
      <MediaQuery minWidth={768}>
        <ThemeProvider theme={appBarTheme}>
          <CssBaseline />
          <AppBar position="static" sx={{ height: '6vh',display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection:'row' }}>
              <Typography key={mansionData?.name} variant="h6" color="inherit" noWrap sx={{ fontSize: '2vh' }}>
                {mansionData?.name}
              </Typography>
              <Box sx={{position:'absolute',right:'1vw',top:'0.5vh'}}>
                <img src={logoIcon} alt="ロゴ" style={{ height: '5vh', marginLeft: 'auto', marginRight: 'auto', objectFit: 'contain' }} />
              </Box>
          </AppBar>
        </ThemeProvider>
        <Grid container spacing={2}>
          {/* 左サイド */}
          <Grid item xs={3} sx={{ height: '76vh' }}>
            <CurrentTime />
            <WeatherForecast mansionData={mansionData} />
            <NewQuestionnaireList />
            <ContactInfo mansionData={mansionData} displayType="contact1" />
          </Grid>

          {/* 中央トップ */}
          <Grid item xs={9}>
            <TopImage />
          </Grid>

          {/* ボトム左 */}
          <Grid item xs={3} sx={{ height: '19vh' }}>
          <QRCodeComponent url1="https://rejipo.com/resident/login"/>
          </Grid>

          { /* ボトム右 */}
          <Grid item xs={9} sx={{ height: '18vh' }}>
            <PublicationList view="resident" />
          </Grid>

        </Grid>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <Box sx={{height: '90vh', width: '100vw',overflow: 'hidden' }}>
        <Grid container spacing={2} sx={{ mt: 0, justifyContent:'space-evenly',height:'100%' }}>
          <Grid item xs={12} sx={{height: '11vh',width: '100vw',pt:'0 !important',m:'auto'}}>
            <MobilePublicationList view="resident" />
          </Grid>
          <Grid item xs={12} sx={{height: '11vh',width: '100vw'}}>
            <MobileNewQuestionnaireList />
          </Grid>
          <Grid item xs={6} sx={{height: '9vh',width: '50vw'}}>
            <MobileCurrentTime />
          </Grid>
          <Grid item xs={6} sx={{height: '9vh',width: '50vw'}}>
            <MobileWeatherForecast mansionData={mansionData} />
          </Grid>
          <Grid item xs={12} sx={{height: '40vh',width: '100vw'}}>
            <MobileTopImage />
          </Grid>
          <Grid item xs={12} sx={{height: '10vh',width: '100vw ',left:'0 !important',bottom:'0 !important',position:'absolute',p:'0 !important'}}>
            <MobileContactInfo mansionData={mansionData} displayType="contact1" />
          </Grid>
        </Grid>
        </Box>
      </MediaQuery>
    </div>
  );
};

export default SignageTemplate4;