import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//apis
import { getQuestionnaires } from '../../../../api/datas'; // 必要に応じてパスを調整

//mui
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';


interface Questionnaire {
    id: number;
    title: string;
    endDate: string;
}

const NewQuestionnaireList: React.FC = () => {
    const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                const data = await getQuestionnaires();
                // 最新の3件のアンケートを取得
                const newQuestionnaires = data.slice(0, 3);
                setQuestionnaires(newQuestionnaires);
            } catch (error) {
                console.error('Error fetching questionnaires:', error);
            }
        };

        fetchQuestionnaires();
    }, []);

    return (
        <Box sx={{ position: 'relative', mr: 2, ml: 2}}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '-10%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    borderRadius: '20px',
                    border: '3px solid black',
                    pl: 2,
                    pr: 2,
                    zIndex: 999,
                    width: 'fit-content',
                    backgroundColor: '#d4c653',
                }}
            >
                <Typography variant="body1" noWrap>アンケート掲示板</Typography>
            </Box>
            <List
                sx={{
                    border: '3px solid black',
                    borderRadius: '10px',
                    pt: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height:'13vh',
                    overflow:'scroll'
                }}
            >
                {questionnaires.map(questionnaire => (
                    <ListItem 
                        key={questionnaire.id}
                        sx={{
                            width:'33%',
                            border:'1px solid #5c5c5b',
                            borderLeft:'20px solid #5c5c5b',
                            borderRadius:'10px',
                            padding:'0.5%',
                            pl:1
                        }}
                    >
                        <ListItemText
                        primary={<Typography variant="body1" noWrap>{questionnaire.title}</Typography>}
                        secondary={questionnaire.endDate}
                        sx={{
                            textWrap:'nowrap'
                        }}
                        />
                    </ListItem>
                ))}
                {questionnaires.length === 0 && (
                    <ListItem sx={{ width: '33%', border: '1px solid #5c5c5b', borderLeft: '20px solid #5c5c5b', borderRadius: '10px', padding: '0.5%', pl: 1 }}>
                        <ListItemText primary={<Typography variant="body1">アンケートがありません</Typography>} />
                    </ListItem>
                )}

            </List>
        </Box>
    );
};

export default NewQuestionnaireList;