import React,{useEffect, useState, useRef} from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  TextField,
  Fab
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//共通コンポーネント
import { useLoadingModal } from '../../common/LoadingModalContext';
import ConfirmDeleteDialog from '../../common/ConfirmDeleteDialog';
import FileViewerDialog from '../../common/FileViewerDialog';
import extractFilenameFromURL from '../../../utils/extractFilenameFromURL';

//firebase
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage} from '../../../api/firebase';

//API
import { getPublicationsByCategory } from '../../../api/datas'; // getPublicationsByCategory 関数をインポート
import { createPublication } from '../../../api/create';
import { updatePublication } from '../../../api/update';
import { deletePublish } from '../../../api/delete';

interface ListingDetailsProps {
    handleMenuClick: (menu: string) => void;
}
const ListingDetails: React.FC<ListingDetailsProps> = ({ handleMenuClick }) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal(); //ローディングの表示非表示
  const [openDeleteDialog,setOpenDeleteDialog] = useState<boolean>(false);
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);

  const [isNew, setIsNew] = useState<boolean>(false)
  const [publications, setPublications] = useState<any | null>(null); // 初期値を null に変更
  const [openModal, setOpenModal] = useState(false);
  const [editingListing, setEditingListing] = useState<any | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>('');

  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const fetchPublications = async () => {
    showLoadingModal();
    const selectedFolder = sessionStorage.getItem('selectedFolder');
    if(!selectedFolder) return;
    try {
      const publicationsData = await getPublicationsByCategory(selectedFolder); // getPublicationsByCategory 関数を呼び出す
      const publicationName = publications.name;
      const currentPublication = publicationsData.find((publication:any) => publication.name === publicationName);
      setPublications(currentPublication);
    } catch (error) {
      console.error('Error fetching publications:', error);
      // エラーメッセージをユーザーに表示するなど、適切な処理を追加
    } finally {
      hideLoadingModal();
      handleFileClear();
    }
  };
  useEffect(() => {
    const publicationData = sessionStorage.getItem('publication');
    if (publicationData) {
      try {
        console.log(publicationData)
        setPublications(JSON.parse(publicationData));
      } catch (error) {
        console.error("Error parsing publication data:", error);
      }
    }
  }, [sessionStorage.getItem('publication')]); // 依存配列に sessionStorage.getItem('publication') を追加

  const handleEditClick = (listing: any) => {
    setIsNew(false)
    setEditingListing(listing);
    setOpenModal(true);
  };

  const handleOpenModal = (type:boolean) => {
    setIsNew(type)
    setOpenModal(true);
    setEditingListing(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingListing(null);
    handleFileClear();
  };

  const handleOpenFileDialog = (fileUrl: string) => {
    setSelectedFileUrl(fileUrl);
    setOpenFileDialog(true);
  };
  const handleCloseFileDialog = () => {
    setOpenFileDialog(false);
  };
  const handleFileDeleteFromFireStorage = async() => {
    try {
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      const category = sessionStorage.getItem('selectedFolder');
      editingListing.attachmentUrls.map((item:string) => {
              const desertRef = ref(storage, `${accountId}/${mansionId}/${category}/${extractFilenameFromURL(item)}`);
              console.log(desertRef)
              deleteObject(desertRef);
            })
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('画像の削除に失敗しました。');
    }
  }

  const handleDeleteConfirm = async(isDelete:boolean) => {
    if(!isDelete){
      setOpenDeleteDialog(false)
      return;
    }
    showLoadingModal();
    await handleFileDeleteFromFireStorage();
    const category = sessionStorage.getItem('selectedFolder');
    const subcategory = publications?.name;
    const publishId = editingListing?.id;
    if(!category || !subcategory || !publishId) {
      console.error(`Invalid Parameter${category},${subcategory},${publishId}`);
      hideLoadingModal();
      return;
    };
    try {
      await deletePublish(category,subcategory,publishId);
    }catch (error){
      console.error(error);
    }finally{
      await fetchPublications();
      hideLoadingModal();
      setOpenDeleteDialog(false)
    }
  }

  const handleSaveListing = async() => {
    if(!editingListing?.title||!editingListing?.details){
      alert('編集内容を入力してください')
      return;
    }
    let attachmentUrls:any = [];
    showLoadingModal();
    if (selectedFiles.length > 0) {
      try {
        const accountId = sessionStorage.getItem('accountId');
        const mansionId = sessionStorage.getItem('mansionId');
        const category = sessionStorage.getItem('selectedFolder');
  
        if (accountId && mansionId && category) {
            
            const uploadTasks = selectedFiles.map((file) => {
              // 保存階層を反映したファイルの参照を作成
              const storageRef = ref(storage, `${accountId}/${mansionId}/${category}/${file.name}`); // ref() を使用して参照を作成
              return uploadBytes(storageRef, file); // uploadTask() を使用してアップロード
            });
    
            const uploadResults = await Promise.all(uploadTasks);
            attachmentUrls = await Promise.all(uploadResults.map((result) => getDownloadURL(result.ref))); // getDownloadURL() を使用して URL を取得
  
        } else {
          console.error('Missing required data for uploading files.');
          throw new Error('Missing required data for uploading files.');
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        hideLoadingModal();
        return;
      }
    }
    // 2. APIの呼び出し
    try{
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      const category = sessionStorage.getItem('selectedFolder');
      const subcategory = publications.name

      if (accountId && mansionId && category && subcategory) {
        const response = await updatePublication( // updatePublication 関数を呼び出す
          editingListing.id,
          accountId,
          mansionId,
          category,
          subcategory,
          editingListing.title,
          editingListing.details,
          attachmentUrls,
          editingListing?.startDate || '',
          editingListing?.endDate || '',
        );
      } else {
        throw new Error(`Invalid Parameter: ${accountId},${mansionId},${category},${subcategory}`)
      }
    }  catch(error) {
      console.log('Error:', error);
      alert(`エラーが発生しました: ${error}`)
    }finally{
      hideLoadingModal();
      handleCloseModal();
      await fetchPublications();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const maxFileSizeInKB = 10240; // 10MBをKBに変換
  
        // 拡張子チェック
        const allowedExtensions = /(\.pdf|\.png|\.jpg)$/i;
        if (!allowedExtensions.exec(file.name)) {
          alert(`${file.name} は許可されていないファイル形式です。`);
          continue; // 次のファイルへ
        }
  
        // サイズチェック
        if (fileSizeInKB > maxFileSizeInKB) {
          alert(`${file.name} はファイルサイズが大きすぎます。`);
          continue; // 次のファイルへ
        }
  
        validFiles.push(file); // 有効なファイルのみ追加
      }
  
      setSelectedFiles(validFiles); // state を更新
      setPreviewUrls(validFiles.map(file => URL.createObjectURL(file))); // プレビュー画像のURLを設定
    } else {
      setSelectedFiles([]);
      setPreviewUrls([]);
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null); // input 要素への ref
  const handleFileClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // input 要素の値をクリア
      setSelectedFiles([]); // selectedFiles state をクリア
      setPreviewUrls([]); // previewUrls state をクリア
    }
  };
  const handleFileUpload = async () => {
    if(!editingListing?.title||!editingListing?.details){
      alert('編集内容を入力してください')
      return;
    }
    let attachmentUrls:any = [];
    showLoadingModal();
    if (selectedFiles.length > 0) {
      try {
        const accountId = sessionStorage.getItem('accountId');
        const mansionId = sessionStorage.getItem('mansionId');
        const category = sessionStorage.getItem('selectedFolder');
        if (accountId && mansionId && category) {
            const storage = getStorage(); // getStorage() を使用して Storage を取得
        
            const uploadTasks = selectedFiles.map((file) => {
              // 保存階層を反映したファイルの参照を作成
              const storageRef = ref(storage, `${accountId}/${mansionId}/${category}/${file.name}`); // ref() を使用して参照を作成
              return uploadBytes(storageRef, file); // uploadTask() を使用してアップロード
            });
    
            const uploadResults = await Promise.all(uploadTasks);
            attachmentUrls = await Promise.all(uploadResults.map((result) => getDownloadURL(result.ref))); // getDownloadURL() を使用して URL を取得
  
        } else {
          console.error('Missing required data for uploading files.');
          alert(`エラーが発生しました:Missing required data for uploading files.`);
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        alert(`エラーが発生しました: ${error}`);
        hideLoadingModal();
        return;
      }
    }
    try {
      // 2. APIの呼び出し
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      const category = sessionStorage.getItem('selectedFolder');
      const subcategory = publications?.name;
      console.log(accountId,mansionId,category,subcategory,editingListing?.title,editingListing?.details)
      if (accountId && mansionId && category && subcategory) {
          const response = await createPublication( // createPublication 関数を呼び出す
          accountId,
          mansionId,
          category,
          subcategory,
          editingListing?.title || '',
          editingListing?.details || '',
          attachmentUrls || [],
          editingListing?.startDate || '',
          editingListing?.endDate || '',
          );  
      } else {
          throw new Error(`Invalid Parameter: ${accountId},${mansionId},${category},${subcategory}`)
      }
    } catch(error) {
      console.log('Error:', error);
      alert(`エラーが発生しました: ${error}`)
    }finally{
      hideLoadingModal();
      setOpenModal(false);
      setEditingListing(null);
      await fetchPublications();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Fab  onClick={()=>handleOpenModal(true)}  color="primary" aria-label="add" style={{width:'150px',height:'150px',position:'absolute',right:'50px',bottom:'50px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <AddIcon style={{cursor:'pointer'}} fontSize={"large"} />
        <Typography>新規追加</Typography>
      </Fab>
      <Box sx={{ height: '5vh' ,marginBottom:'10px'}}>
        <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={()=>{handleMenuClick('listings')}} />
      </Box>
      <Typography variant="h4" style={{marginLeft:'10px'}}>
            {publications?.name}
      </Typography>
      <Divider style={{border:'5px solid #65a7e9',marginBottom:'10px'}}/>
      <Grid container spacing={5} rowGap={0} direction={'row'} style={{height:'50vh',overflow:'scroll'}}>
          {publications?.data && publications.data.map((publication:any) => (
            publication?.title && (
            <Grid item xs={12} sm={6} md={4} key={publication.id} style={{height:'fit-content'}}>
              <Card sx={{ border: '1px solid #ccc'}}>
                <Box sx={{width:'100%',height:'fit-content',backgroundColor:'whitesmoke',p:1,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Typography variant="h6">{publication?.title}</Typography>
                    <Box>
                        <EditIcon style={{cursor:'pointer'}} onClick={() => handleEditClick(publication)}/>
                        <DeleteIcon color='error' style={{cursor:'pointer'}} onClick={()=>{setOpenDeleteDialog(true);setEditingListing(publication);}}/>
                    </Box>
                </Box>
                <Box sx={{height:'80px',p:2}}>
                    <Typography variant="body1">作成日：{new Date(publication?.createdAt?._seconds * 1000).toLocaleDateString('sv-SE')}</Typography>
                    <Typography variant="body1">更新日：{new Date(publication?.updatedAt?._seconds * 1000).toLocaleDateString('sv-SE')}</Typography>
                </Box>
                {/* その他の掲載情報 */}
              </Card>
            </Grid>
            )
          ))}
      </Grid>
      {/* 編集・新規追加モーダル */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" component="h2">
            {isNew ? '新規追加' : '編集'}
          </Typography>
            <>
              <TextField label="件名" name="title" defaultValue={editingListing?.title}  fullWidth margin="normal" 
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                setEditingListing({ ...editingListing, title: event.target.value })
              }
              />
              <TextField label="詳細" name="details" multiline rows={4} defaultValue={editingListing?.details} fullWidth margin="normal"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                setEditingListing({ ...editingListing, details: event.target.value })
              }
              />
              <TextField label="掲載開始日" name="startDate" defaultValue={editingListing?.startDate}  fullWidth margin="normal" type='date'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                setEditingListing({ ...editingListing, startDate: event.target.value })
              }
              InputLabelProps={{ shrink: true }} // ラベルを常にシュリンク
              />
              <TextField label="掲載終了日" name="endDate" defaultValue={editingListing?.endDate}  fullWidth margin="normal" type='date' 
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditingListing({ ...editingListing, endDate: event.target.value })
                console.log(event.target.value)
                }
              }
              InputLabelProps={{ shrink: true }} // ラベルを常にシュリンク
              />
              {/* ファイルアップロード */}
              <Card>
              <CardContent>
              {!isNew && 
                <Grid container spacing={1}>
                {editingListing?.attachmentUrls && editingListing?.attachmentUrls.map((item:string, index:number) => (
                  <Grid item xs={4} key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={()=>{handleOpenFileDialog(item)}}>
                    <Typography variant='body1' noWrap sx={{textDecoration:'underline',cursor:'pointer',}}>
                      {//ファイル名を抽出
                        extractFilenameFromURL(item)
                      }
                    </Typography>
                    <OpenInNewIcon sx={{cursor:'pointer',}}/>
                  </Grid>
                ))}
                </Grid>
              }
              <input type="file" aria-label='+ファイルを選択' style={{ }} multiple onChange={handleFileChange} ref={fileInputRef} /> {/* ref を設定 */}
              {selectedFiles.map((selectedFile, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography>{selectedFile.name}</Typography>
                  <Button variant="text" color="error" onClick={() => {
                    // 選択されたファイルを解除
                    const newSelectedFiles = [...selectedFiles];
                    newSelectedFiles.splice(index, 1);
                    setSelectedFiles(newSelectedFiles);

                    // プレビューURLも更新
                    const newPreviewUrls = [...previewUrls];
                    newPreviewUrls.splice(index, 1);
                    setPreviewUrls(newPreviewUrls);
                  }}>
                    削除
                  </Button>
                </Box>
                ))}
                { selectedFiles.length > 0 &&
                    <Button variant="text" color="primary" onClick={handleFileClear}> {/* 選択解除ボタン */}
                    選択解除
                    </Button>
                }
                </CardContent>
              </Card>
              <Box sx={{display:'flex',justifyContent:'flex-end'}}>
              <Button variant="text" color="error" onClick={handleCloseModal} >
                キャンセル
              </Button>
              <Button variant="text" color="primary" onClick={isNew ? handleFileUpload : handleSaveListing  } >
                保存する
              </Button>
              </Box>
            </>
        </DialogContent>
      </Dialog>
      <ConfirmDeleteDialog
        open={openDeleteDialog}
        onClose={(isDelete)=>{handleDeleteConfirm(isDelete)}}
        title="削除確認"
        message="本当に削除しますか？"
        okLabel="削除"
        cancelLabel="キャンセル"
      />
      <FileViewerDialog
        fileUrl={selectedFileUrl}
        open={openFileDialog}
        onClose={handleCloseFileDialog}
      />
    </Box>
  );
};

export default ListingDetails;