import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MediaQuery from 'react-responsive';
import {
  Grid,
  AppBar,
  Typography,
  Box,
  Button
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//apis
import { getMansionData } from '../../../api/datas';

import logoIcon from '../../../assets/images/icons/logo.svg'

//components
import CurrentTime from './components/CurrentTime';
import WeatherForecast from './components/WeatherForecast';
import NewQuestionnaireList from './components/NewQuestionnaireList';
import ContactInfo from './components/ContactInfo';
import QRCodeComponent from './components/QRCodeComponent';
import TopImage from './components/TopImage';
import PublicationList from './components/PublicationList';
import NewPublicationList from './components/NewPublicationList';

//mobileComponents
import MobileCurrentTime from './componentsForMobile/CurrentTime';
import MobileWeatherForecast from './componentsForMobile/WeatherForecast';
import MobileNewQuestionnaireList from './componentsForMobile/NewQuestionnaireList';
import MobileContactInfo from './componentsForMobile/ContactInfo';
import MobileTopImage from './componentsForMobile/TopImage';
import MobilePublicationList from './componentsForMobile/PublicationList';
import MobileNewPublicationList from './componentsForMobile/NewPublicationList';


const SignageTemplate3: React.FC = () => {
  const [mansionData, setMansionData] = useState<any>([]);

  const fetchMansionData = async () => {
    if(sessionStorage.getItem('mansionId') === null){
      setTimeout(() => {
          fetchMansionData();
        }, 1000)
    }
    const data = await getMansionData();
    setMansionData(data);
  }
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0); 
  const sliderRef = useRef<Slider>(null); // Slider コンポーネントへの参照
  const slideChangeHandler = (index: number) => {
      setCurrentSlideIndex(index);
      sliderRef.current?.slickGoTo(index)
  };
  useEffect(() => {
    //URLパラメータからAccountIdとMansionIDを取得
    const searchParams = window.location.search;
    if (!searchParams) return;
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get('accountId');
    const mansionId = urlParams.get('mansionId');
    if (accountId && mansionId) {
      sessionStorage.setItem('accountId', accountId);
      sessionStorage.setItem('mansionId', mansionId);
      try {
        fetchMansionData();
      } catch (error) {
        console.log(error);
      }
    }
    return;
  }
    , [window.location.search]);
  // AppBar 用のテーマ
  const appBarTheme = createTheme({
    palette: {
      primary: {
        main: mansionData?.themeColor || '#65a7e9', // selectedMansion があれば themeColor を、なければデフォルト色を使用
      },
    },
  });
  const setting = {
    initialSlide: 0,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: false,
    appendDots: (dots: any) => {
      return (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 10, mb: 2, position: 'absolute', bottom: '-50%', left: 0, width: '100%', height: '5vh' }}>
          <Button variant="text" color="inherit" style={{borderRadius:0,borderBottom:currentSlideIndex==0? '3px solid #8ecde2':'none'}} onClick={() => slideChangeHandler(0)}><Typography>{"TOP画像"}</Typography></Button>
          <Button variant="text" color="inherit" style={{borderRadius:0,borderBottom:currentSlideIndex==1? '3px solid #8ecde2':'none'}} onClick={() => slideChangeHandler(1)}><Typography>{"新着情報"}</Typography></Button>
          </Box>
      )
  }
  }
  return (
    <div>
      <MediaQuery minWidth={768}>
        <ThemeProvider theme={appBarTheme}>
        <AppBar position="static" sx={{ height: '6vh',display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection:'row' }}>
              <Typography key={mansionData?.name} variant="h6" color="inherit" noWrap sx={{ fontSize: '2vh' }}>
                {mansionData?.name}
              </Typography>
              <Box sx={{position:'absolute',right:'1vw',top:'0.5vh'}}>
                <img src={logoIcon} alt="ロゴ" style={{ height: '5vh', marginLeft: 'auto', marginRight: 'auto', objectFit: 'contain' }} />
              </Box>
          </AppBar>
        </ThemeProvider>
        <Grid container spacing={2}>
          {/* 左サイド */}
          <Grid item xs={3} sx={{ height: '76vh' }}>
            <CurrentTime />
            <WeatherForecast mansionData={mansionData} />
            <NewQuestionnaireList />
            <ContactInfo mansionData={mansionData} displayType="both" />
          </Grid>

          {/* 中央トップ */}
          <Grid item xs={7} sx={{ height: '76vh' }}>
            <TopImage />
          </Grid>

          <Grid item xs={2} sx={{ height: '76vh'}}>
            <NewPublicationList newsData={mansionData.newsForContractor} />
          </Grid>

          {/* ボトム左 */}
          <Grid item xs={3} sx={{ height: '19vh' }}>
            <QRCodeComponent url1="https://rejipo.com/resident/login"/>
          </Grid>

          { /* ボトム右 */}
          <Grid item xs={9} sx={{ height: '18vh' }}>
            <PublicationList view="vendor" />
          </Grid>

        </Grid>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <Box sx={{height: '90vh', width: '100vw',overflow: 'hidden' }}>
        <Grid container spacing={2} sx={{ mt: 0,justifyContent:'space-evenly',height:'100%' }}>
          <Grid item xs={12} sx={{height: '10vh',width: '100vw',pt:'0 !important',m:'auto'}}>
            <MobilePublicationList view="vendor" />
          </Grid>
          <Grid item xs={12} sx={{height: '15vh',width: '100vw'}}>
            <MobileNewQuestionnaireList />
          </Grid>
          <Grid item xs={6} sx={{height: '5vh',width: '50vw'}}>
            <MobileCurrentTime />
          </Grid>
          <Grid item xs={6} sx={{height: '5vh',width: '50vw'}}>
            <MobileWeatherForecast mansionData={mansionData} />
          </Grid>
          <Grid item xs={12} sx={{height: '45vh',width: '100vw'}}>
            <Slider {...setting} ref= {sliderRef}>
              <MobileTopImage />
              <MobileNewPublicationList newsData={mansionData.newsForContractor} />
            </Slider>
          </Grid>
          <Grid item xs={12} sx={{height: '10vh',width: '100vw ',left:'0 !important',bottom:'0 !important',position:'absolute',p:'0 !important'}}>
            <MobileContactInfo mansionData={mansionData} displayType="both" />
          </Grid>
        </Grid>
        </Box>
      </MediaQuery>
    </div>
  );
};

export default SignageTemplate3;