import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";

export const firebaseConfig = {
  apiKey: "AIzaSyAYKbdUl3UHwVhHlv5G6cAo-nzFbam8WAE",
  authDomain: "sumasai.firebaseapp.com",
  projectId: "sumasai",
  storageBucket: "sumasai.appspot.com",
  messagingSenderId: "202758912020",
  appId: "1:202758912020:web:c23d70094b892ccc3a0f01"
};
//Firebase.initializeApp()を何度も呼び出すことを防ぐために以下のコードで対応
export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

export const storage = getStorage(firebaseApp);
export const db = getFirestore();