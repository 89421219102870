import React, { useRef } from 'react';
import {
  useSortable,
} from '@dnd-kit/sortable';
import {
  Box,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PdfViewer from './PdfViewer';

interface Props {
  id: string;
  item: any;
  setIsOpenDialog: (isOpen: boolean) => void;
  setEditingImage: (image: any) => void;
  setIsEditing: (isEditing: boolean) => void;
  setIsOpenDeleteConfirmDialog: (isOpen: boolean) => void;
}

export const SortableItem = ({ id, item, setIsOpenDialog, setEditingImage, setIsEditing, setIsOpenDeleteConfirmDialog }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
  } = useSortable({ id });


  return (
    <ImageListItem
      key={item.id}
      ref={setNodeRef}
      sx={{ border: 'solid 1px #ccc', aspectRatio: '1/1', opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}
      {...attributes}
      {...listeners}
    >
      <ImageListItemBar
        title={item?.title}
        position='top'
        sx={{
          backgroundColor: 'white',
          '& div': {
            color: 'black'
          }
        }}
        actionIcon={
          <Box>
            <IconButton
              sx={{ color: 'red' }}
              aria-label={`info about ${item.name}`}
              onClick={
                () => {
                  setIsOpenDeleteConfirmDialog(true);
                  setEditingImage(item);
                }
              }
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              sx={{ color: 'black' }}
              aria-label={`info about ${item.name}`}
              onClick={
                () => {
                  setIsOpenDialog(true);
                  setEditingImage(item);
                  setIsEditing(true);
                }
              }
            >
              <EditIcon />
            </IconButton>
          </Box>
        }
      />
      {item.type === 'application/pdf' ?
        <PdfViewer fileUrl={item.url} />
        :
        <img
          src={item.url}
          alt="Top Image"
          loading="lazy"
          style={{ height: '80%', aspectRatio: '1/1', objectFit: 'cover' }}
        />
      }
      <Box sx={{ height: '20%', pl: 1 }}>
        <Typography>
          作成日：{item?.uploadedAt?._seconds ? new Date(item.uploadedAt._seconds * 1000).toLocaleDateString('sv-SE') : ''}
        </Typography>
      </Box>
    </ImageListItem>
  );
};
