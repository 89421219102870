import React, { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Typography,
  AppBar,
  styled,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Collapse,
} from '@mui/material';

import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import { getMansionData,getMyQuestionnaire } from '../../../../../api/datas'; // getMansions 関数をインポート
import { postAnswer } from '../../../../../api/create';

import './ResidentMypage.scss';
import QuestionnaireResponseDialog from '../../../../common/QuestionnaireResponseDialog';
import { useLoadingModal } from '../../../../common/LoadingModalContext';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  backgroundColor: 'gray',
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 128,
  },
}));


const ResidentMypage: React.FC = () => {
  const accountId = sessionStorage.getItem('accountId'); // アカウントIDを取得
  const mansionId = sessionStorage.getItem('mansionId'); //マンションIDを取得
  const residentData = sessionStorage.getItem('residentData'); //ResidentDataを取得
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // アンケート一覧の開閉状態
  const { showLoadingModal, hideLoadingModal } = useLoadingModal(); //ローディングの表示非表示

  const [mansionData, setMansionData] = useState<any>(null); //マンションデータ
  const [urlParam, setUrlParam] = useState<string>('');
  const [questionnaires, setQuestionnaires] = useState<any[]>([]); // アンケートデータ
  const [dialogOpen, setDialogOpen] = useState(false); // ダイアログの開閉状態
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<any | null>(null); // 選択されたアンケートID

  const fetchMansionData = async () => {
    try {
      const mansionsData = await getMansionData();
      console.log(mansionsData);
      setMansionData(mansionsData);
    } catch (error) {
      console.error('Error fetching mansions:', error);
      // エラーメッセージをユーザーに表示するなど、適切な処理を追加
    }
  };
  useEffect(() => {
    if (mansionId) {
      showLoadingModal();
      try {
        fetchMansionData();
      } catch (error) {
        console.error('Error fetching mansions:', error);
      } finally {
        hideLoadingModal();
      }
    }
  }, [mansionId]);


  const handleOpenDialog = (questionnaire: any) => {
    setSelectedQuestionnaire(questionnaire);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async(questionnaireId:string,answer:any) => {
    showLoadingModal();
    try {
        await postAnswer(questionnaireId, answer);
        await fetchQuestionnaires();
      } catch (error) {
        console.error('Error submitting answer:', error);
        alert('回答の送信に失敗しました。');
      } finally {
        handleCloseDialog();
        hideLoadingModal();
      }
  }
  const fetchQuestionnaires = async () => {
    try {
      showLoadingModal();
      const questionnairesData = await getMyQuestionnaire(); // getQuestionnaires 関数を呼び出す
      // **ここからソート処理**
      const sortedQuestionnaires = questionnairesData.sort((a:any, b:any) => {
        const aCreatedAt = a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000;
        const bCreatedAt = b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000;
        return bCreatedAt - aCreatedAt; // 新しいものが先頭になるように降順にソート
      });
      setQuestionnaires(sortedQuestionnaires);
      console.log(questionnairesData);
    } catch (error) {
      console.error('Error fetching questionnaires:', error);
      // エラーメッセージをユーザーに表示するなど、適切な処理を追加
    } finally {
      hideLoadingModal();
    }
  };
  useEffect(() => {
    setUrlParam(`?accountId=${accountId}&mansionId=${mansionId}`);
    fetchQuestionnaires(); 
  }, [mansionId, accountId]); 

  const handleLogout = () => {
    // TODO: ログアウト処理を実装
    navigate('/resident/login');
  };
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{backgroundColor: 'whitesmoke', width: '100vw', height: '100vh'}}>
   <AppBar position="static">
      <StyledToolbar>
        <Grid direction={'column'} style={{width: '50%', display: 'flex', justifyContent: 'space-between', alignContent: 'flex-start'}} container spacing={2} alignItems="center">
        <Grid item xs={12} style={{width: '100%'}}>
        <Typography style={{textAlign:"left"}} noWrap>
            {mansionData && mansionData.name}
          </Typography>
          <Typography style={{textAlign:"left"}}>
            {residentData && JSON.parse(residentData).name}
          </Typography>
        </Grid>  
        </Grid>
      </StyledToolbar>
    </AppBar>
    <Container style={{ marginTop: 4, marginBottom: 4 ,display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <Button onClick={() => window.open('/signage/'+mansionData?.template+urlParam,'_blank')} color='inherit' variant='contained' className='vendor-dashboard__button' style={{marginTop:'20px'}}>
        <Typography variant='h6'>掲載画面を確認する</Typography>
      </Button>
        {/* 回答可能なアンケートの一覧 */}
        {mansionId &&
        <List color='inherit' style={{borderBottom: '1px solid gray', boxShadow: ' 0 4px 4px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px',height:'100%', width: '70%', margin: '20px auto'}} className='vendor-dashboard__button'>
          <ListItemButton onClick={handleClick}>
            <ListItemText primary="アンケート一覧" sx={{textAlign: 'center'}} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse style={{height:'40vh',overflow:'scroll'}} in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2} sx={{height:'40vh',overflow:'scroll'}}>
              {questionnaires.map((question) => {
                          // **ここから回答期限のチェック**
            const now = new Date(); 
            const endDate = new Date(question.endDate);
            const isExpired = now > endDate; // 回答期限切れかどうか
                return(
                <Grid item xs={6} key={question.id}>
                  <ListItem sx={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                    {question.non_respondent.find((user:any) => user.id === sessionStorage.getItem('residentId')) ?
                    <Button size='small' variant="contained" color="error" sx={{cursor:'default'}}>未回答</Button> 
                    : 
                    <Button size='small' variant="contained" color="success" sx={{cursor:'default'}}>回答済</Button>
                    }
                    <ListItemText primary={question.title} secondary={`回答期限：${question.endDate}`} />
                    {question.non_respondent.find((user:any) => user.id === sessionStorage.getItem('residentId')) ?
                    <Button size='small' variant="text" color="inherit" sx={{cursor:'default'}} disabled={isExpired} onClick={() => handleOpenDialog(question)} >アンケートに答える</Button> 
                    : 
                    <Button size='small' variant="text" color="inherit" sx={{cursor:'default'}} disabled>アンケートに答える</Button>
                    }
                  </ListItem>
                  <Divider />
                </Grid>
              )}
              )}
            </Grid>
          </Collapse>
        </List>
        }
      <Button style={{display: 'flex', justifyContent: 'center', margin:'auto', textDecoration: 'underline'}} variant="text" color="inherit" onClick={handleLogout}>
        ログアウトする
      </Button>
    </Container>
    {/* 回答ダイアログ */}
    <QuestionnaireResponseDialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      questionnaire={selectedQuestionnaire}
      onSubmit={handleSubmit}
  />
    </div>
  );
};

export default ResidentMypage;