import React from 'react';
import {
    Typography,
    Grid,
    Link,
    Box
} from '@mui/material';

//import original icon
import 工事責任者Icon from '../../../../assets/images/icons/color/工事責任者.svg';
import 管理会社Icon from '../../../../assets/images/icons/color/管理会社.svg';

interface MansionData {
    contact1_tel: string;
    contact2_tel: string;
    contact1: string;
    contact2: string;
}

interface ContactInfoProps {
    mansionData: MansionData;
    displayType: 'contact1' | 'both';
}

const ContactInfo: React.FC<ContactInfoProps> = ({ mansionData, displayType }) => {
    const { contact1_tel, contact2_tel, contact1, contact2 } = mansionData;

    return (
        <Box
            sx={{
                backgroundColor: '#e2dede',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid container sx={{display:'flex',justifyContent:'center'}}>
                <Grid item xs={1} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img width={30} height={30} src={管理会社Icon} />
                </Grid>
                <Grid item xs={displayType === 'both'? 5:8}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',

                        }}
                        onClick={() => {
                            window.open(`tel:${contact1_tel}`, '_blank');
                        }}
                    >
                        <Typography variant="body1" noWrap>
                            {contact1}
                        </Typography>
                        <Typography variant="body1" noWrap>
                            {contact1_tel}
                        </Typography>
                    </Box>
                </Grid>

                {displayType === 'both' && (
                    <Grid item xs={1} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', pl: 1 }}>
                        <img width={30} height={30} src={工事責任者Icon} />
                    </Grid>
                )
                }
                {displayType === 'both' && (
                    <Grid item xs={5} sx={{ pl: 1 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            onClick={() => {
                                window.open(`tel:${contact2_tel}`, '_blank');
                            }}
                        >
                            <Typography variant="body1" noWrap>
                                {contact2}
                            </Typography>
                            <Typography variant="body1" noWrap>
                                {contact2_tel}
                            </Typography>
                        </Box>
                    </Grid>
                )
                }
            </Grid>
        </Box>
    );
};

export default ContactInfo;