import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTopImages } from '../../../../api/datas'; // 必要に応じてパスを調整
import PdfViewer from '../../../common/PdfViewer';
import './TopImage.css'; // CSSファイルを作成し、インポート


const TopImage: React.FC = () => {
  const [imageDatas, setImageDatas] = useState<any[]>([]);

  useEffect(() => {
    const fetchTopImages = async () => {
      try {
        const data = await getTopImages();
        setImageDatas(data);
      } catch (error) {
        console.error('Error fetching top images:', error);
      }
    };

    fetchTopImages();
  }, []);

  const settings = {
    fade: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    arrows: imageDatas.length > 1, // 画像が複数ある場合のみ矢印を表示
    // 以下を追加
    adaptiveHeight: false, // 画像の高さを自動調整
    
  };

  return (
    <div className="top-image-slider" style={{ textAlign: 'center', width: '100%',height:'95%', marginTop:'2%'}}>
      <Slider {...settings}>
        {imageDatas.length === 0 && <p>Loading...</p>}
        {imageDatas?.filter((imageData) => imageData?.url).map((imageData, index) => (
          <div key={index} style={{width:'100%',height:'70vh',objectFit:'contain'}}>
            {imageData.type === 'application/pdf' ? (
              <div style={{width:'auto',height:'70vh',overflow:'scroll'}}>
                <PdfViewer fileUrl={imageData.url} />
              </div>
            ) : (
            <img src={imageData.url} alt={`top-image-${index}`} style={{width:'auto',height:'70vh',marginTop:'2vh',margin:'auto'}}  />
            )
            }
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TopImage;