import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//apis
import { getQuestionnaires } from '../../../../api/datas'; // 必要に応じてパスを調整

//mui
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';


interface Questionnaire {
    id: number;
    title: string;
    endDate: string;
}

const NewQuestionnaireList: React.FC = () => {
    const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                const data = await getQuestionnaires();
                // 最新の3件のアンケートを取得
                const newQuestionnaires = data.slice(0, 3);
                setQuestionnaires(newQuestionnaires);
            } catch (error) {
                console.error('Error fetching questionnaires:', error);
            }
        };

        fetchQuestionnaires();
    }, []);

    return (
        <Box sx={{ position: 'relative', mr: 2, ml: 2, mt: window.screen.width > 1800 ? 7 : 1 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: window.screen.width > 1800 ? '-1.5vh' : '-2vh',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    borderRadius: '20px',
                    border: '3px solid black',
                    pl: 2,
                    pr: 2,
                    zIndex: 999,
                    width: 'fit-content',
                    backgroundColor: '#d4c653',
                }}
            >
                <Typography variant="h6" noWrap sx={{ fontSize: '2vh' }}>アンケート掲示板</Typography>
            </Box>
            <List
                sx={{
                    border: '3px solid black',
                    borderRadius: '10px',
                    pt: window.screen.width > 1800 ? 10 : 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '33vh',
                    overflow: 'scroll'
                }}
            >
                {questionnaires.map(questionnaire => (
                    <ListItem
                        key={questionnaire.id}
                        sx={{
                            width: '90%',
                            border: '1px solid #5c5c5b',
                            borderLeft: '20px solid #5c5c5b',
                            borderRadius: '10px',
                            padding: '0.5%',
                            pl: 2
                        }}
                    >
                        <ListItemText
                            primary={<Typography sx={{
                                fontSize: '2vh',
                                overflow: 'hidden',        // はみ出た部分を隠す
                                textOverflow: 'ellipsis', // 省略記号 (...) を表示
                                whiteSpace: 'nowrap',    // 折り返しを禁止
                                display: 'block',         // ブロックレベル要素にする (width を有効にするため)
                            }}>{questionnaire.title}</Typography>}
                            secondary={<Typography sx={{ fontSize: '1.5vh' }}>{questionnaire.endDate}</Typography>}
                        // textWrapは削除
                        />
                    </ListItem>
                ))}
                {questionnaires.length === 0 && (
                    <ListItem sx={{ width: '90%', border: '1px solid #5c5c5b', borderLeft: '20px solid #5c5c5b', borderRadius: '10px', padding: '0.5%', pl: 2 }}>
                        <ListItemText>
                            <Typography sx={{ fontSize: '2vh' }}>アンケートはありません</Typography>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

export default NewQuestionnaireList;