import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

const CurrentTime = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const optionsWeekday: Intl.DateTimeFormatOptions = { weekday: 'short', timeZone: 'Asia/Tokyo' };
  const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Tokyo' };
  const weekday = time.toLocaleDateString('ja-JP', optionsWeekday).slice(0, 1);
  const dateString = `${time.toLocaleDateString('ja-JP', optionsDate)}(${weekday})`;

  return (
    <Box sx={{width:'100%',height:'100%',textAlign: 'center',display:'flex',gap:0,flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline',alignContent: 'center', justifyContent: 'center',mb:1 }}>
        <Typography variant="h3" sx={{ fontSize: '1.5rem' }}>
          {time.getHours()}:{("0" + time.getMinutes()).slice(-2)}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          <span style={{ fontSize: '1rem' }}>{("0" + time.getSeconds()).slice(-2)}</span>
        </Typography>
      </Box>
      <Typography variant="h3" sx={{ fontSize: '1.2rem',mt:-1 }}>
        {dateString}
      </Typography>
    </Box>
  );
};

export default CurrentTime;