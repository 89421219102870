import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Container,
  Card,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
//icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'; // 必要に応じて別のアイコンに変更
import logoIcon from './images/logo.svg';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Close } from '@mui/icons-material';

import './ResidentLogin.scss'; // 必要に応じてスタイルを調整

import { residentLogin } from '../../../../../api/client'; // residentLogin をインポート

//Loading画面
import { useLoadingModal } from '../../../../common/LoadingModalContext';

// 画像をインポート (必要に応じて変更)
import mailImage from './images/box_MAIL.png';
import telImage from './images/box_TEL.png';

const theme = createTheme();

const ResidentLogin: React.FC = () => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal();
  useEffect(() => {
    sessionStorage.clear();
    const currentNavigation = window.location.pathname;
    if (currentNavigation !== '/resident/login') {
      window.location.href = '/resident/login';
    }
  }, []);
  const navigate = useNavigate();
  const [Id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoadingModal();
    try {
      const accountData = await residentLogin(Id, password); // residentLogin を呼び出す
      // ログイン成功時の処理
      console.log('Login successful:', accountData);
      navigate('/resident/mypage'); // リダイレクト先を変更
    } catch (error: any) {
      // ログイン失敗時の処理
      console.error('Login failed:', error.message);
      alert(error.message);
    } finally {
      hideLoadingModal();
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{minWidth: '50px',maxWidth: '100px', width:'15vw',aspectRatio: '1/1'}}>
            <img src={logoIcon} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="ロゴ" />
          </Box>
          <Card className='vendor-login__card'>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="Id"
                label="ID"
                name="Id"
                autoComplete="Id"
                autoFocus
                value={Id}
                onChange={(e) => setId(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{ // パスワード表示/非表示ボタンを追加
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
              {/* FormControlLabel を削除 */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ログイン
              </Button>
              <Button variant='text' color='inherit' style={{ display: 'flex', justifyContent: 'center', margin: 'auto', textDecoration: 'underline' }} onClick={handleOpenModal}>
                パスワードを忘れた場合
              </Button>
            </Box>
          </Card>
          <Button style={{ marginTop: '20px' }} variant="contained" color="success"  onClick={()=>{navigate("/vendor/login")}}>
            <ChangeCircleIcon />
            {"業者の方はこちら"}
          </Button>
          {/* ベンダー向けの要素を削除 */}
          <Grid container style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: '20px' }}>
            <Grid item>
              <Button
                variant='text'
                color='inherit'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  textDecoration: 'underline'
                }}
                onClick={() => { window.open('https://firebasestorage.googleapis.com/v0/b/sumasai.appspot.com/o/Common%2F%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84docx.pdf?alt=media&token=e6eb45d8-3390-490a-ac32-f9d05d648be2') }}
              >
                利用規約
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                color='inherit'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  textDecoration: 'underline'
                }}
                onClick={() => { window.open('https://firebasestorage.googleapis.com/v0/b/sumasai.appspot.com/o/Common%2F%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC.docx.pdf?alt=media&token=68b001f3-c0e8-4f9d-a0ee-1a7e64c0637f') }}
              >
                プライバシーポリシー
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* モーダル */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
      >
        <DialogContent>
          <DialogTitle style={{ textAlign: 'center' }}>
            お問い合わせ
            <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }}>
              <Close color='inherit' onClick={handleCloseModal} />
            </IconButton>
          </DialogTitle>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            {/* 問い合わせ先情報を表示 (必要に応じて変更) */}
            パスワードを忘れてしまった方、<br />
            そのほか、お困りごとがある方は<br />
            下記にご連絡ください。<br />
          </Typography>
          <img className='resident-login__image' src={mailImage} /> {/* 必要に応じてクラス名 resident-login__image を変更 */}
          <img className='resident-login__image' src={telImage} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ResidentLogin;