import React from 'react';
import { Backdrop, CircularProgress, Box } from '@mui/material';
import { createPortal } from 'react-dom'; // or 'react-dom/client' for React 18+
import { useLoadingModal } from './LoadingModalContext'; // Import the context

const LoadingModal: React.FC = () => {
    const { isLoading } = useLoadingModal();
    return createPortal( // Create a portal to render the modal outside the current component hierarchy
        <Backdrop open={isLoading} invisible={!isLoading} sx={{ color: '#fff', zIndex: 9999 }}> {/* Use 'invisible' to keep Backdrop in DOM but hidden */}
                <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress color="inherit" /></Box>
        </Backdrop>,
        document.body // Append the modal to the body element
      );
}

export default LoadingModal;

