import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

interface Props {
  fileUrl: string;
}

const ExcelViewer: React.FC<Props> = ({ fileUrl }) => {
  const [data, setData] = useState<any[][] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
      const sheetName = workbook.SheetNames[0]; 
      const worksheet = workbook.Sheets[sheetName];
      const jsonData:any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(jsonData);
    };

    fetchData();
  }, [fileUrl]);

  return (
    <div>
      {data && (
        <table>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExcelViewer;