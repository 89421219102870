import React, { createContext, useState, useContext } from 'react';

interface LoadingModalContextType {
  isLoading: boolean;
  showLoadingModal: () => void;
  hideLoadingModal: () => void;
}

const LoadingModalContext = createContext<LoadingModalContextType | undefined>(undefined);

export const LoadingModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoadingModal = () => setIsLoading(true);
  const hideLoadingModal = () => setIsLoading(false);

  return (
    <LoadingModalContext.Provider value={{ isLoading, showLoadingModal, hideLoadingModal }}>
      {children}
    </LoadingModalContext.Provider>
  );
};

export const useLoadingModal = () => {
  const context = useContext(LoadingModalContext);
  if (context === undefined) {
    throw new Error('useLoadingModal must be used within a LoadingModalProvider');
  }
  return context;
};
