import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CheckBoxOutlined } from '@mui/icons-material';
import {BlockPicker} from 'react-color';

import { updateMansionData } from '../../../api/update';
import { getMansionData } from '../../../api/datas';

import { useLoadingModal } from '../../common/LoadingModalContext';

interface MansionData {
  address: string;
  contact1: string;
  contact1_tel: string;
  contact2: string;
  contact2_tel: string;
  contractEndDate: string;
  contractStartDate: string;
  id: string;
  name: string;
  newsForContractor: any[];
  newsForResident: any[];
  templates: any;
  themeColor: string;
}

interface MansionSettingsProps {
  mansionData: MansionData;
  handleMenuClick: (menu:string) => void; // handleMenuClick の型を定義
}


const MansionSettings: React.FC<MansionSettingsProps> = ({ mansionData, handleMenuClick }) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal();
  const [editedMansionData, setEditedMansionData] = useState<MansionData>(mansionData);
  const [isEdit, setIsEdit] = useState<boolean> (false);
  const [openPicker, setOpenPicker] = useState<boolean> (false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMansionData({ ...editedMansionData, [event.target.name]: event.target.value });
  };
  const handleColorChange = (color: any) => {
    setEditedMansionData({ ...editedMansionData, themeColor: color.hex });
  };

  const handleSave = async() => {
    showLoadingModal();
    try{
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      const name = editedMansionData.name;
      const address = editedMansionData.address;
      const themeColor = editedMansionData.themeColor;
      const contact1 = editedMansionData.contact1;
      const contact1_tel = editedMansionData.contact1_tel;
      const contact2 = editedMansionData.contact2;
      const contact2_tel = editedMansionData.contact2_tel;

      if(accountId && mansionId){
        await updateMansionData(
          name, 
          address, 
          contact1, 
          contact1_tel, 
          contact2, 
          contact2_tel, 
          themeColor
         );
        setIsEdit(false);
      }else{
        console.error("アカウントIDまたはマンションIDが取得できませんでした。")
      }

    } catch(error) {
      console.error("エラーが発生しました:", error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      const updatedData = await getMansionData();
      if(updatedData){
        setEditedMansionData(updatedData);
      }
      hideLoadingModal();
    }

  };

  return (
    <Box key={mansionData.id} sx={{ p: 3 }}>
      <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={()=>{handleMenuClick('')}} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width:'70vw',margin:'auto'}}>
        <TextField label="マンション名" name="name" value={editedMansionData.name} onChange={handleChange} fullWidth margin="normal" disabled={!isEdit} />
        <TextField label="住所" name="address" value={editedMansionData.address} onChange={handleChange} fullWidth margin="normal" disabled={!isEdit}/>
        <Box sx={{ display:'flex', flexDirection:'row',alignItems:'center'}}>
          <TextField disabled={true} label="天気予報設定" name="address" value={editedMansionData.address} margin="normal" sx={{width:'80%'}} />
          <CheckBoxOutlined />
          <Typography>住所と同じ</Typography>
        </Box>
        <Box sx={{ display:'flex', flexDirection:'row',alignItems:'center'}}>
          {/**プレビュー */}
          <Box sx={{width:'3%',aspectRatio:'1/1',backgroundColor:editedMansionData.themeColor,mr:2,position:'relative'}} onClick={()=>{setOpenPicker(!openPicker)}}>
          {openPicker && (
              <Box sx={{ position: 'absolute', zIndex: 10, top: '130%',left:'-215%' }}>
                <BlockPicker color={editedMansionData.themeColor} onChangeComplete={handleColorChange} />
              </Box>
            )}
          </Box>
          <TextField label="テーマカラー" name="themeColor" value={editedMansionData.themeColor} onChange={handleChange}  margin="normal" sx={{width:'97%'}} disabled={!isEdit} />
        </Box>
        <Box  sx={{ display:'flex', flexDirection:'row',alignItems:'center', gap:5}}>
          <TextField label="管理会社名" name="contact1" value={editedMansionData.contact1} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled={!isEdit}/>
          <TextField label="管理会社連絡先" name="contact1_tel" value={editedMansionData.contact1_tel} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled={!isEdit}/>
        </Box>
        <Box  sx={{ display:'flex', flexDirection:'row',alignItems:'center', gap:5}}>
          <TextField label="工事責任者名" name="contact2" value={editedMansionData.contact2} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled={!isEdit}/>
          <TextField label="工事責任者連絡先" name="contact2_tel" value={editedMansionData.contact2_tel} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled={!isEdit}/>
        </Box>
        <Box  sx={{ display:'flex', flexDirection:'row',alignItems:'center', gap:5}}>
          <TextField label="契約開始日" name="contractStartDate" value={editedMansionData.contractStartDate} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled/>
          <TextField label="契約終了日" name="contractEndDate" value={editedMansionData.contractEndDate} onChange={handleChange} margin="normal" sx={{width:'50%'}} disabled/>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection:'row',alignItems:'center', gap:5 }}>
          {isEdit?
            <>
            <Button variant="text" color="error" onClick={()=>{setIsEdit(false);setEditedMansionData(mansionData)}} sx={{ mt: 1 }}>
              キャンセル
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 1 }}>
              保存する
            </Button>
            </>
            :
            <Button variant="contained" color="primary" onClick={()=>{setIsEdit(true)}} sx={{ mt: 1 }}>
              編集する
            </Button>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default MansionSettings;