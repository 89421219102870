import React, { useState, useEffect, use } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Popover,
    Box,
    Typography,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Radio,
    RadioGroup,
} from '@mui/material';
//Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Question from './Question';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PersonIcon from '@mui/icons-material/Person';
//apis
import { getResidentGroups } from '../../api/datas';

import { v4 as uuid4 } from 'uuid';

//firebase
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../api/firebase';

// Questionコンポーネントの型定義を再利用
interface QuestionProps {
    question: {
        id: string;
        text: string;
        type: 'text' | 'number' | 'dropdown' | 'checkbox' | 'date' | 'multiple-date' | 'maru-batsu';
        options?: string[];
        min?: number;
        max?: number;
        startDate?: string;
        endDate?: string;
        // multiple-date タイプ用
        dateRanges?: { startDate: string; endDate: string }[];
    };
    answer: any;
    onAnswerChange: (questionId: string, answer: any) => void;
    editable: boolean;
    isPreview?: boolean;
    onQuestionChange: (questionId: string, question: any) => void;
    isEditing?: boolean; // 編集モードかどうか
}
const Templates = [
    {
        id: uuid4(),
        text: '',
        type: 'text',
    }, {
        id: uuid4(),
        text: '',
        type: 'maru-batsu',
    }, {
        id: uuid4(),
        text: '',
        type: 'multiple-date',
        dateRanges: [{ startDate: '', endDate: '' }, { startDate: '', endDate: '' }, { startDate: '', endDate: '' },]
    }
]

interface QuestionnaireDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (questionnaire: any) => void;
    questionnaire?: any; // 編集時はアンケートデータを受け取る
    isEditing?: boolean; // 編集モードかどうか
}

const QuestionnaireDialog: React.FC<QuestionnaireDialogProps> = ({
    open,
    onClose,
    onSave,
    questionnaire,
    isEditing,
}) => {
    const [editingQuestionnaire, setEditingQuestionnaire] = useState<any>({
        title: '',
        startDate: '',
        endDate: '',
        questions: [],
        attachmentUrls: []
    });
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const [isOpenCheckResidentGroup, setIsOpenCheckResidentGroup] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [residentGroups, setResidentGroups] = useState<any>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<string>('');
    const [selectedTemplate, setSelectedTemplate] = useState<number>(0)

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpenPopover(true);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setIsOpenPopover(false);
    };
    useEffect(() => {
        if (questionnaire) {
            setEditingQuestionnaire(questionnaire);
            questionnaire.questions[0].type === 'text' ? setSelectedTemplate(0) :
                questionnaire.questions[0].type === 'maru-batsu' ? setSelectedTemplate(1) :
                    setSelectedTemplate(2);
            
        }
    }, [questionnaire]);
    useEffect(() => {
        handleTemplateChange(0);
    }, []);
    const fetchResidentGroups = async () => {
        try {
            const residentGroupsData = await getResidentGroups();
            setResidentGroups(residentGroupsData);
        } catch (error) {
            console.error('Error fetching resident groups:', error);
            alert('Resident Groupsの取得に失敗しました。');
        }
    };
    useEffect(() => {
        fetchResidentGroups();
    }, []);
    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingQuestionnaire({ ...editingQuestionnaire, title: event.target.value });
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingQuestionnaire({ ...editingQuestionnaire, startDate: event.target.value });
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingQuestionnaire({ ...editingQuestionnaire, endDate: event.target.value });
    };

    const handleTemplateChange = (templateNumber: number) => {
        setSelectedTemplate(templateNumber);
        setEditingQuestionnaire({
            ...editingQuestionnaire,
            questions: [Templates[templateNumber]],
        });
    }
    const handleAddQuestion = () => {
        const newQuestion: QuestionProps['question'] = {
            id: uuid4(),
            text: '',
            type: 'text', // デフォルトの質問タイプ
        };
        setEditingQuestionnaire({
            ...editingQuestionnaire,
            questions: [...editingQuestionnaire.questions, newQuestion],
        });
    };

    const handleQuestionChange = (questionId: string, updatedQuestion: QuestionProps['question']) => {
        setEditingQuestionnaire((prevQuestionnaire: any) => ({
            ...prevQuestionnaire,
            questions: prevQuestionnaire.questions.map((q: QuestionProps['question']) =>
                q.id === questionId ? updatedQuestion : q,
            ),
        }));
    };

    const handleDeleteQuestion = (questionId: string) => {
        setEditingQuestionnaire((prevQuestionnaire: any) => ({
            ...prevQuestionnaire,
            questions: prevQuestionnaire.questions.filter((q: QuestionProps['question']) => q.id !== questionId),
        }));
    };

    const handleSave = async() => {
        // 値のチェック
        if (!editingQuestionnaire.title) {
            alert('タイトルを入力してください。');
            return;
        }
        if (!editingQuestionnaire.startDate || !editingQuestionnaire.endDate) {
            alert('掲載期間を入力してください。');
            return;
        }
        if (editingQuestionnaire.questions.length === 0) {
            alert('質問を追加してください。');
            return;
        }
        let attachmentUrls: any = [];
        try {
            attachmentUrls = await handleFileUpload();
        } catch (error) {
            alert('ファイルのアップロードに失敗しました。');
            return;
        }
        onSave({ ...editingQuestionnaire, attachmentUrls: attachmentUrls });
        onClose(); // 保存後にダイアログを閉じる
        //ダイアログの初期化
        setEditingQuestionnaire({
            title: '',
            startDate: '',
            endDate: '',
            questions: [],
        });
        handleTemplateChange(0);
        setSelectedGroupId('');
        setIsOpenCheckResidentGroup(false);
        handleClosePopover();
    };

    //画像添付の処理
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedFiles(Array.from(files));
        }
    };
    //画像アップロードの処理
    const handleFileUpload = async () => {
        if (selectedFiles.length > 0) {
            let attachmentUrls: any = [];
            try {
                const accountId = sessionStorage.getItem('accountId');
                const mansionId = sessionStorage.getItem('mansionId');

                if (accountId && mansionId) {
                    const uploadTasks = selectedFiles.map((file) => {
                        // 保存階層を反映したファイルの参照を作成
                        const storageRef = ref(storage, `${accountId}/${mansionId}/questionnaire`); // ref() を使用して参照を作成
                        return uploadBytes(storageRef, file); // uploadTask() を使用してアップロード
                    });

                    return Promise.all(uploadTasks)
                    .then((uploadResults) => {
                      const downloadURLPromises = uploadResults.map((result) => getDownloadURL(result.ref));
                      return Promise.all(downloadURLPromises);
                    })
                    .then((attachmentUrls) => {
                      console.log('Attachment URLs:', attachmentUrls);
                      return attachmentUrls; // string[] 型の値を返す
                    })
                    .catch((error) => {
                      console.error('Error uploading files:', error);
                      throw error; // エラーをthrowして呼び出し元で処理できるようにする
                    });
                } else {
                    console.error('Missing required data for uploading files.');
                    throw new Error('Missing required data for uploading files.');
                }
            } catch (error) {
                console.error('Error uploading files:', error);
                throw new Error('Error uploading files:' + error);
            }
        }
    };


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{isEditing ? 'アンケート編集' : '新規アンケート追加'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="タイトル"
                    value={editingQuestionnaire.title}
                    onChange={handleTitleChange}
                    fullWidth
                    margin="normal"
                    disabled={isEditing} // 編集時は件名変更不可
                />
                {/**質問テンプレート選択 */}
                <Box
                    sx={{
                        border: '1px solid #ccc',
                        p: 2,
                        mb: 2,
                        mt: 2,
                        borderRadius: '10px',
                    }}
                >
                    {Templates.map((template: any, index: number) => (
                        <RadioGroup
                            key={template.id}
                            value={selectedTemplate}
                            onChange={(e) => handleTemplateChange(index)}
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Radio
                                value={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}
                            />
                            <Question
                                key={template.id}
                                question={isEditing? (selectedTemplate === index ? editingQuestionnaire.questions[0] :template) : template}
                                answer={null}
                                editable={false}
                                onAnswerChange={() => { }}
                                isEditing={selectedTemplate === index}
                                onQuestionChange={handleQuestionChange}
                            />
                        </RadioGroup>
                    ))}
                </Box>
                {/**掲載期間設定 */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                >
                    <TextField
                        label="掲載開始日"
                        type="date"
                        value={editingQuestionnaire.startDate}
                        onChange={handleStartDateChange}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '30%' }}
                    />
                    <Typography variant="subtitle1" sx={{ ml: 5, mr: 5 }}>〜</Typography>
                    <TextField
                        label="掲載終了日"
                        type="date"
                        value={editingQuestionnaire.endDate}
                        onChange={handleEndDateChange}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '30%' }}
                    />
                </Box>
                <Box sx={{
                    display: isEditing ? 'none' : 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    
                }}
                >
                    <input type="file" multiple accept=".pdf,.png,.jpg" onChange={handleFileChange} />
                </Box>
                {/* 質問一覧 
                {editingQuestionnaire.questions.map((question: QuestionProps['question'], index: number) => (
                    <Box key={question.id} sx={{ mt: 2, border: '1px solid #ccc', p: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="subtitle1">質問 {index + 1}</Typography>
                            {!isEditing && ( // 編集モードでない場合のみ削除ボタンを表示
                                <Box>
                                    <IconButton onClick={(event) => { handleOpenPopover(event) }} size="small">
                                        <ChangeCircleIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteQuestion(question.id)} size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            )}
                            <Popover
                                open={isOpenPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                            >
                                <Box sx={{
                                    backgroundColor: 'white',
                                    border: '1px solid gray',
                                    boxShadow: '0 0 5px gray',
                                    p: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1
                                }}>
                                    <Typography variant='body2'>質問タイプを選択</Typography>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'text' })}>テキスト</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'number' })}>数値</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'dropdown' })}>ドロップダウン</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'checkbox' })}>チェックボックス</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'date' })}>日付</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'multiple-date' })}>複数日付</Button>
                                    <Button onClick={() => handleQuestionChange(question.id, { ...question, type: 'maru-batsu' })}>マルバツ</Button>
                                </Box>
                            </Popover>
                        </Box>
                        {editingQuestionnaire &&
                            <Question
                                key={`${question.id}-${question.type}`}
                                question={question}
                                answer={null} // 回答は不要
                                editable={false}
                                onAnswerChange={() => { return }}
                                isEditing={!isEditing} // QuestionnaireDialogの編集モードとQuestionコンポーネントの編集モードは逆
                                onQuestionChange={handleQuestionChange}
                            />
                        }
                    </Box>
                ))}
                */}
                {/* 質問追加ボタン 非表示
                {!isEditing && ( // 編集モードでない場合のみ追加ボタンを表示
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddQuestion}
                        sx={{ mt: 2 }}
                    >
                        質問を追加
                    </Button>
                )}
                */}
                {/** 配信先選択 */}
                {!isEditing &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                    >
                        <FormControl sx={{ width: '30%' }} margin="normal">
                            <InputLabel>配信先</InputLabel>
                            <Select
                                key={selectedGroupId}
                                label="配信先"
                                value={selectedGroupId || ''}
                                onChange={(event) => {
                                    setSelectedGroupId(event.target.value);
                                    setEditingQuestionnaire({
                                        ...editingQuestionnaire,
                                        non_respondent: residentGroups.find((group: any) => group.id === event.target.value)?.members
                                    });

                                }}
                            >
                                {residentGroups.map((group: any) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                ml: 2,
                                backgroundColor: 'white !important',
                                color: 'black !important',
                                ':hover': {
                                    backgroundColor: 'whitesmoke !important'
                                },
                                display: selectedGroupId ? 'block' : 'none'
                            }}
                            onClick={
                                () => {
                                    setIsOpenCheckResidentGroup(true);
                                }
                            }
                            disabled={!selectedGroupId}
                        >
                            配信先を確認
                        </Button>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button key={editingQuestionnaire} onClick={handleSave} variant="contained" color="primary">
                    保存
                </Button>
            </DialogActions>
            <Dialog open={isOpenCheckResidentGroup} onClose={() => setIsOpenCheckResidentGroup(false)} maxWidth={'md'}>
                <DialogTitle>{residentGroups.find((group: any) => group.id === selectedGroupId)?.name}のアカウント一覧</DialogTitle>
                <DialogContent>
                    {residentGroups.find((group: any) => group.id === selectedGroupId)?.members.length > 0 ?
                        <List sx={{ height: '45vh', overflowY: 'auto' }}>
                            {residentGroups.find((group: any) => group.id === selectedGroupId)?.members.map((resident: any) => (
                                <ListItem key={resident.id}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={resident.name} secondary={`部屋番号：${resident.roomNumber}`} />
                                </ListItem>
                            ))}
                        </List>
                        :
                        <Typography>メンバーがいません。グループ管理でメンバーを追加してください。</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpenCheckResidentGroup(false)}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

export default QuestionnaireDialog;