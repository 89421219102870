//TOP画像を編集するページ
import React, { useState, useEffect, useRef } from 'react';
import {
    Divider,
    Box,
    Typography,
    Button,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

//firebase
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from '../../../api/firebase';

//apis
import { createMainImage } from '../../../api/create';
import { updateMainImageIndex } from '../../../api/update';
//import { updateTopImage } from '../../../api/update';
import { deleteMainImage } from '../../../api/delete';

//loading
import { useLoadingModal } from '../../common/LoadingModalContext';
import PdfViewer from '../../common/PdfViewer';

import { getTopImages } from '../../../api/datas';

//Delete-Confirm
import ConfirmDeleteDialog from '../../common/ConfirmDeleteDialog';

// dnd-kit
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './../../common/MainImageItem';

interface TopImageManagementProps {
    handleMenuClick: (menu: string) => void;
}
const imageDataTemplate = {
    title: "",
    description: "",
    name: "",
    type: "image/png",
    storagePath: "",
    mansionId: "",
    index: 99,
    id: "",
    url: ""
}

const TopImageManagement: React.FC<TopImageManagementProps> = ({ handleMenuClick }) => {
    const { showLoadingModal, hideLoadingModal } = useLoadingModal();
    const [topImageUrls, setTopImageUrls] = useState<any | null>([]);
    const [newImage, setNewImage] = useState<any>(imageDataTemplate);
    const [editingImage, setEditingImage] = useState<any | null>(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10, // ポインターを 10px 移動させるまでドラッグ操作を開始しない
                delay: 200, // ポインターを押してから 200ms 経過するまでドラッグ操作を開始しない
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = async (event: any) => {
        showLoadingModal();
        const { active, over } = event;
        if (active.id !== over.id) {
            setTopImageUrls((items: any) => {
                const oldIndex = items.findIndex((item: any) => item.id === active.id);
                const newIndex = items.findIndex((item: any) => item.id === over.id);

                const updatedItems = arrayMove(items, oldIndex, newIndex);

                // 並び順を更新
                updatedItems.forEach((item: any, index: number) => {
                    item.index = index;
                });

                return updatedItems;
            });

            // APIリクエスト用の配列を作成
            const newArray = topImageUrls.map((item: any) => ({
                id: item.id,
                index: item.index
            }));

            console.log('MOVE', newArray);
            try {
                await updateMainImageIndex(newArray);
            } catch (error) {
                console.error(error);
            } finally {
                await fetchTopImage();
            }
        }
        hideLoadingModal();
    };

    const fetchTopImage = async () => {
        showLoadingModal();
        setTopImageUrls([]);
        try {
            const response = await getTopImages();
            //indexがあるデータだけ
            const filteredResponse = response.filter((item: any) => item?.index !== undefined)
            console.log(filteredResponse);
            setTopImageUrls(filteredResponse);
        } catch (error) {
            console.error('Error fetching top images:', error);
        } finally {
            hideLoadingModal();
        }
    }
    useEffect(() => {
        fetchTopImage();
    }, [sessionStorage.getItem('mansionId')]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        file && setSelectedFile(file);
        console.log(file);
    };
    const handleClickSave = async () => {
        showLoadingModal();
        if (isEditing) {
            if (!editingImage.title) {
                alert('件名を入力してください');
                hideLoadingModal();
                console.log(editingImage)
                return;
            }
            if (!editingImage.description) {
                alert('詳細を入力してください');
                hideLoadingModal();
                console.log(editingImage)
                return;
            }
        } else {
            if (!newImage.title) {
                alert('件名を入力してください');
                hideLoadingModal();
                console.log(newImage)
                return;
            }
            if (!newImage.description) {
                alert('詳細を入力してください');
                hideLoadingModal();
                console.log(newImage)
                return;
            }
        }
        let imageData: any;
        try {
            if (selectedFile) {
                if (isEditing) {
                    await handleDeleteFile();
                }
                imageData = await handleUploadFile();
            }
        } catch (error) {
            console.error('Error saving image:', error);
            alert('画像の保存に失敗しました。');
            return;
        }
        if (isEditing) {
            await updateMainImage(imageData);
        }
        else {

            await handleCreateMainImage(imageData);
        }
        setEditingImage(null);
        setNewImage(imageDataTemplate);
        setSelectedFile(null);
        fileInputRef.current!.value = '';
        setIsEditing(false);
        setIsOpenDialog(false);
        hideLoadingModal();
    }
    const updateMainImage = async (imageData: any) => {

    }
    const handleCreateMainImage = async (imageData: any) => {
        try {
            console.log('NEW', newImage)
            const response = await createMainImage(
                imageData
            );
            await fetchTopImage();
            setNewImage(imageDataTemplate);
            setSelectedFile(null);
            fileInputRef.current!.value = '';
            setIsOpenDialog(false);
        } catch (error) {
            console.error('Error creating main image:', error);
            alert('メイン画像の作成に失敗しました。');
        }
    }
    const handleDeleteMainImage = async (isDelete: boolean) => {
        if (!isDelete) {
            setIsOpenDeleteConfirmDialog(false);
            return;
        }
        showLoadingModal();
        try {
            await handleDeleteFile();
            await deleteMainImage(editingImage.id);
        } catch (error) {
            console.error('Error deleting main image:', error);
            alert('メイン画像の削除に失敗しました。');
        } finally {
            await fetchTopImage();
            setEditingImage(null);
            setIsOpenDeleteConfirmDialog(false);
            hideLoadingModal();
        }
    }
    const handleUploadFile = async () => {
        try {
            if (selectedFile) {
                const accountId = sessionStorage.getItem('accountId');
                const mansionId = sessionStorage.getItem('mansionId');
                const storageRef = ref(storage, `${accountId}/${mansionId}/main/${selectedFile.name}`);
                await uploadBytes(storageRef, selectedFile);
                const storagePath = `${accountId}/${mansionId}/main/${selectedFile.name}`
                const filename = selectedFile.name
                const filetype = selectedFile.type
                let imageData: any;
                if (isEditing) {
                    imageData = {
                        ...editingImage,
                        storagePath: storagePath,
                        name: filename,
                        type: filetype,
                    }
                } else {
                    imageData = {
                        ...newImage,
                        storagePath: storagePath,
                        name: filename,
                        type: filetype,
                    }
                }
                return imageData;
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('画像のアップロードに失敗しました。');
        }
    };

    const handleDeleteFile = async () => {
        try {
            const accountId = sessionStorage.getItem('accountId');
            const mansionId = sessionStorage.getItem('mansionId');
            const desertRef = ref(storage, `${accountId}/${mansionId}/main/${editingImage.name}`);
            await deleteObject(desertRef);
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('画像の削除に失敗しました。');
        }
    }

    const handleClickCancel = () => {
        setIsOpenDialog(false);
        setNewImage(imageDataTemplate);
        setSelectedFile(null);
        setEditingImage(null);
        setIsEditing(false);
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ height: '5vh' }}>
                <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={() => { handleMenuClick('') }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h4" style={{ marginLeft: '10px', marginRight: '30px' }}>
                    メイン画像
                </Typography>
                <Button variant="contained" color="primary" onClick={() => { setIsOpenDialog(true); setIsEditing(false); }}>
                    <AddIcon /> 画像を追加
                </Button>
            </Box>
            <Divider style={{ border: '5px solid #65a7e9', marginBottom: '10px' }} />
            <Box>
                {topImageUrls ? (
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={topImageUrls} strategy={horizontalListSortingStrategy}> {/* ここに topImageUrls を渡す */}
                            <ImageList gap={15} sx={{ height: '50vh', width: '80vw', flexWrap: 'nowrap', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                {topImageUrls?.sort((a: any, b: any) => a.index - b.index).map((item: any) => (
                                    item?.url &&
                                    <SortableItem key={item.id} id={item.id} item={item} setIsOpenDialog={setIsOpenDialog} setEditingImage={setEditingImage} setIsEditing={setIsEditing} setIsOpenDeleteConfirmDialog={setIsOpenDeleteConfirmDialog} />
                                ))}
                            </ImageList>
                        </SortableContext>
                    </DndContext>
                ) : (
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        画像がありません
                    </Typography>
                )}

            </Box>
            {/**画像追加ダイアログ */}
            <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)} maxWidth='md'>
                <DialogTitle alignSelf={'center'}>{isEditing ? '画像編集' : '新規追加'}</DialogTitle>
                {isEditing ?
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField label='件名' margin='normal' value={editingImage?.title} onChange={(e) => setEditingImage({ ...editingImage, title: e.target.value })} />
                        <TextField label='詳細' margin='normal' value={editingImage?.description} onChange={(e) => setEditingImage({ ...editingImage, description: e.target.value })} />
                    </DialogContent>
                    :
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField label='件名' margin='normal' value={newImage?.title} onChange={(e) => setNewImage({ ...newImage, title: e.target.value })} />
                        <TextField label='詳細' margin='normal' value={newImage?.description} onChange={(e) => setNewImage({ ...newImage, description: e.target.value })} />
                        <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} />
                        {selectedFile && <Typography>{selectedFile.name}</Typography>}
                        {selectedFile && (
                            selectedFile.type.startsWith('image/') ?
                            <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{ height: '10vh', objectFit: 'cover' }} />
                            :
                            <PdfViewer fileUrl={URL.createObjectURL(selectedFile)} />
                            )
                        }
                    </DialogContent>
                }
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button variant='text' color='error' onClick={() => { handleClickCancel(); }}>キャンセル</Button>
                        <Button variant='text' color='primary' onClick={() => { handleClickSave(); }} >保存</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <ConfirmDeleteDialog
                open={isOpenDeleteConfirmDialog}
                onClose={
                    (isDelete) => { handleDeleteMainImage(isDelete); }
                }
                title="削除確認"
                message="本当に削除しますか？"
                okLabel="削除"
                cancelLabel="キャンセル"
            />
        </Box>
    );
};

export default TopImageManagement;
