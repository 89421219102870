import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    List,
    ListItemText,
    ListItemButton,
    Collapse,
    Typography,
    Card,
    CardContent,
    Grid,
    Drawer,
    Divider
} from '@mui/material';
import 設備情報Icon from '../../../../assets/images/icons/color/設備情報.svg';
import お知らせIcon from '../../../../assets/images/icons/color/お知らせ.svg';
import 理事会資料Icon from '../../../../assets/images/icons/color/理事会資料.svg';
import 注意事項Icon from '../../../../assets/images/icons/color/注意事項.svg';
import 工事Icon from '../../../../assets/images/icons/color/工事.svg';
import 工程表Icon from '../../../../assets/images/icons/color/工程表.svg';
import 書類一覧Icon from '../../../../assets/images/icons/color/書類一覧.svg';
import NewIcon from '../../../../assets/images/icons/color/NEW.svg'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FileViewerDialog from '../../../common/FileViewerDialog'; // FileViewerDialog をインポート
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';

import { getPublicationsByCategory, getMansionData } from '../../../../api/datas';

interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationCategory {
    name: string;
    data: Publication[];
}

interface PublicationListProps {
    view: 'resident' | 'vendor';
}

const PublicationList: React.FC<PublicationListProps> = ({ view }) => {
    const [mansionData,setMansionData] = useState<{} | null>(null)
    const [publications, setPublications] = useState<PublicationCategory[]>([]);
    const [folders,setFolders] = useState({
        resident: ['設備情報', 'お知らせ', '理事会資料', '注意事項'],
        vendor: ['工事', 'お知らせ', '工程表', '書類一覧', '注意事項'],
    });
    const [openFolder, setOpenFolder] = useState<string | null>(null);
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<Publication | null>(null);
    const [openFileViewer, setOpenFileViewer] = useState(false); // FileViewerDialog の開閉状態を管理する state
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null); // 選択されたファイルの URL を保持する state
    const [newsForContractor, setNewsForContractor] = useState<Publication[]>([]);
    const [newsForResident, setNewsForResident] = useState<Publication[]>([]);

    const fetchPublications = async (selectedCategory: string) => {
        try {
            const data = await getPublicationsByCategory(selectedCategory);
            setPublications(data);
        } catch (error) {
            console.error('Error fetching publications:', error);
        }
    };
    useEffect(() => {
        getMansionData().then(result => {
            setMansionData(result);
            if (result?.categoryForContractor) {
                setFolders({ ...folders, vendor: result.categoryForContractor })
            }
            if (result?.categoryForResident) {
                setFolders({ ...folders, resident: result.categoryForResident })
            }
            if (result?.newsForContractor) {
                setNewsForContractor(result.newsForContractor)
            }
            if (result?.newsForResident) {
                setNewsForResident(result.newsForResident)
            }
        })
    }, []);

    // サブカテゴリ名に対応するアイコンを取得する関数
    const getIcon = (folder: string) => { // 戻り値の型を React.FunctionComponent に変更
        switch (folder) {
            case '設備情報':
                return 設備情報Icon;
            case 'お知らせ':
                return お知らせIcon;
            case '理事会資料':
                return 理事会資料Icon;
            case '注意事項':
                return 注意事項Icon;
            case '工事':
                return 工事Icon;
            case '工程表':
                return 工程表Icon;
            case '書類一覧':
                return 書類一覧Icon;
                case '洗濯物情報':
                    return 工事Icon;
            // ... 他のサブカテゴリに対応するアイコンを設定
            default:
                return 'null';
        }
    };

    const handleFolderClick = (folder: string) => {
        setOpenFolder(folder);
        setOpenCategory(null); // フォルダを開いたときにカテゴリを閉じる
        setSelectedPost(null); // フォルダを開いたときに選択された投稿をリセット
        fetchPublications(folder);
    };

    const handleCategoryClick = (category: string) => {
        if (category === openCategory) {
            setOpenCategory(null);
        } else {
            setOpenCategory(category);
        }
        setSelectedPost(null); // カテゴリを開いたときに選択された投稿をリセット
    };

    const handlePostClick = (post: Publication) => {
        setSelectedPost(post);
    };

    const handleAttachmentClick = async (fileUrl: string) => {
        setSelectedFileUrl(fileUrl);
        setOpenFileViewer(true);
    };

    const handleCloseFileViewer = () => {
        setOpenFileViewer(false);
        setSelectedFileUrl(null);
    };
    function extractFilename(url: string) {
        try {
            const urlObj = new URL(url);
            let pathname = urlObj.pathname;
            // 2回URLデコード
            pathname = decodeURIComponent(pathname);
            pathname = decodeURIComponent(pathname);
            const filenameWithParams = pathname.substring(pathname.lastIndexOf('/') + 1);
            const filename = filenameWithParams.split('?')[0]; // クエリパラメータを除去
            return filename;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }

    return (
        <div style={{ textAlign: 'center', width: '100%', height: '100%', paddingBottom: '0px' }}>
            {/* フォルダボタン */}
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto'
                }}>
                {folders[view].map((folder) => ( // category に基づいてフォルダを表示
                    <div key={`folder-${folder}`}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}
                    >
                        <Button
                            key={`button-${folder}`}
                            variant="contained"
                            onClick={() => handleFolderClick(folder)}
                            sx={{
                                backgroundColor: '#e2dede',
                                ':hover': { backgroundColor: 'e2dede' },
                                borderRadius: '100%',
                                color: 'black',
                                height: '70%',
                                aspectRatio: '1/1'
                            }}
                        >
                            <Typography><img width={70} height={70} src={getIcon(folder)} alt={folder} /></Typography>
                        </Button>
                        <Typography variant="body1" noWrap>
                            {folder}
                        </Typography>
                    </div>
                ))}
            </div>

            {/* ダイアログ */}
            <Drawer
                anchor='bottom'
                open={!!openFolder}
                onClose={() => setOpenFolder(null)}
                sx={{
                    height: '90vh', // 例: 高さ80%
                    '& .MuiDrawer-paper': { // Drawerのpaper部分
                        height: '90vh'
                    }
                }}>
                <Grid container >
                    {/* 上：カテゴリ */}
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: 'auto',
                        }}
                    >
                        <Box sx={{ width: '50%' }}>
                            <Typography variant="h6" gutterBottom>
                                {openFolder}
                            </Typography>
                            <Divider sx={{ border: '2px solid #757272' }} />
                        </Box>
                        <CloseIcon onClick={() => setOpenFolder(null)} />
                    </Box>
                    <Grid item xs={12}>
                        {publications.length > 0 ?
                            <List sx={{ height: '20vh', overflow: 'scroll' }}>
                                {publications
                                    .map((publication) => (
                                        <div key={publication.name} style={{ width: '90%', margin: 'auto' }}>
                                            <ListItemButton
                                                onClick={() => handleCategoryClick(publication.name)}
                                                sx={{ 
                                                    backgroundColor: '#20baee', 
                                                    ':hover': { backgroundColor: '#b9b9b9' }, 
                                                    color: 'white',
                                                    borderRadius:'15px',
                                                    mb:1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={publication.name}
                                                />
                                                {openCategory === publication.name ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={openCategory === publication.name} timeout="auto" unmountOnExit sx={{mt:1,mb:1}}>
                                                <Grid container direction={'row'} >
                                                    {publication.data.map((post) => (
                                                        <Grid item xs={4} key={post.id}
                                                            onClick={() => handlePostClick(post)}
                                                            sx={{ 
                                                                border: '2px solid  #757272',
                                                                borderRadius:'5px',
                                                                padding: '5px',
                                                                backgroundColor: selectedPost?.id === post.id ? '#20baee' : 'white',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'flex-start',
                                                         }}
                                                        >
                                                            <Typography variant="body1" noWrap>
                                                                {post.title || 'タイトルなし'} 
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Collapse>
                                        </div>
                                    ))}
                            </List>
                            :
                            <Typography>掲載がありません</Typography>
                        }
                    </Grid>

                    {/* 下側：掲載内容 */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ height: '40vh',width:'90%',margin:'auto', overflow: 'scroll', backgroundColor: '#e2dede', borderRadius: '20px' }}>
                            {selectedPost &&
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {selectedPost.title || selectedPost.id}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        {selectedPost.startDate} - {selectedPost.endDate}
                                    </Typography>
                                    <Card sx={{ height: '35vh', overflow: 'scroll', backgroundColor: 'white', border: '2px solid  #757272', mb: 2 }}>
                                        <CardContent>
                                            {selectedPost.details && <Typography variant="body1">{selectedPost.details}</Typography>}
                                        </CardContent>
                                    </Card>
                                    {selectedPost.attachmentUrls && selectedPost.attachmentUrls.length > 0 && (
                                        <div>
                                            {selectedPost.attachmentUrls.map((url, index) => (
                                                <Grid direction={'row'} gap={2} container key={index} sx={{ width: '100%' }}>
                                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #757272', borderRadius: '10px' }}>
                                                        {/* 添付ファイルの表示 */}
                                                        <Typography
                                                            variant="body2"
                                                            onClick={() => handleAttachmentClick(url)}
                                                            sx={{ cursor: 'pointer', color: '#20baee' }}
                                                            noWrap
                                                        >
                                                            {/**URLからファイル名だけを抜粋 */}
                                                            {extractFilename(url)}
                                                        </Typography>
                                                        <OpenInNewIcon color='inherit' />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </div>
                                    )}
                                </CardContent>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Drawer>
            {/* FileViewerDialog */}
            <FileViewerDialog
                fileUrl={selectedFileUrl || ''} // 選択されたファイルの URL を渡す
                open={openFileViewer}
                onClose={handleCloseFileViewer}
            />
        </div>
    );
};

export default PublicationList;