const extractFilenameFromURL = (url: string) =>  {
    try {
        // URLをデコード
        const decodedUrl = decodeURIComponent(url);

        // URLオブジェクトを作成
        const urlObject = new URL(decodedUrl);

        // パスの部分を抽出
        const path = urlObject.pathname;

        // パスの最後のスラッシュ以降をファイル名として抽出
        const filename = path.substring(path.lastIndexOf('/') + 1);

        return decodeURIComponent(filename);
    } catch (error) {
        console.error("URLの解析に失敗しました:", error);
        return "";
    }
}

export default extractFilenameFromURL;