// src/api/create.ts
import * as api from './api'; // api.ts をインポート
import convertHalfWidthNonNumericToFullWidth from '../utils/convertHalfWidthNonNumericToFullWidth'

export const createPublication = async (
  accountId: string,
  mansionId: string,
  category: string,
  subcategory: string,
  title: string,
  details: string,
  attachmentUrls: any,
  startDate: string,
  endDate: string,
) => {
  try {
    const response = await fetch(api.createPublishEndpoint+`?accountId=${accountId}&mansionId=${mansionId}`, { // auth.ts からエンドポイントを取得
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        subcategory,
        title,
        details,
        attachmentUrls,
        startDate,
        endDate,
      }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating publication:', error);
    throw error;
  }
};

export const createSubCategory = async(
  category: string,
  subcategory: string,
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  subcategory = convertHalfWidthNonNumericToFullWidth(subcategory);
  try {
    const response = await fetch(api.createSubCategoryEndpoint+`?accountId=${accountId}&mansionId=${mansionId}&category=${category}&subcategory=${subcategory}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });
  } catch (error) {
    console.error('Error creating subcategory:', error);
    throw error;
  }
}

export const createMainImage = async(
  imageData:any
) => {
  const accountId =sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.createMainImageEndpoint,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId: accountId,
        mansionId: mansionId,
        name: imageData.name,
        storagePath: imageData.storagePath,
        type: imageData.type,
        title: imageData.title,
        description: imageData.description
      })
    });
  } catch (error) {
    console.error('Error creating main image:', error);
    throw error;
  }
}

export const createQuestionnaire = async(
  questionnaire:any,
) => {
  console.log('before createQuestionnaire',questionnaire);
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  const title = questionnaire.title;
  const startDate = questionnaire.startDate;
  const endDate = questionnaire.endDate;
  const questions = questionnaire.questions;
  const memberIds = questionnaire.non_respondent;
  const attachmentUrls = questionnaire.attachmentUrls;
  try {
    const response = await fetch(api.createQuestionnaireEndpoint,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId,
        mansionId,
        title,
        startDate,
        endDate,
        questions,
        memberIds,
        attachmentUrls
      })
    });
  } catch (error) {
    console.error('Error creating questionnaire:', error);
    throw error;
  }
}

export const postAnswer = async(questionnaireId:string,answer:any) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  const residentId = sessionStorage.getItem("residentId");
  try{
    const response = await fetch(api.postAnswerEndpoint,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          answer,
          questionnaireId,
          residentId,
          mansionId,
          accountId
        }
      )
    });
    if(!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
    return await response.text();
  } catch (error) {
    console.error('Error posting answer:', error);
    throw error;
  }
}

export const createResidentAccount = async(
  name: string,  
  roomNumber: string,
  email?: string,
  password?: string
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.createResidentAccountEndpoint+`?accountId=${accountId} `,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        roomNumber,
        email,
        password,
        mansionId
      })
    });
  } catch (error) {
    console.error('Error creating resident account:', error);
    throw error;
  }
}

export const createResidentGroup = async(
  name: string,
  description: string,
) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  try {
    const response = await fetch(api.createResidentGroupEndpoint+`?accountId=${accountId}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        description,
        mansionId
      })
    });
  } catch (error) {
    console.error('Error creating resident group:', error);
    throw error;
  }
}
