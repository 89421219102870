import React, { useState, useEffect, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

// PDF.js worker の設定
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Props {
  fileUrl: string;
}

const PdfViewer: React.FC<Props> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingProgress, setLoadingProgress] = useState(0); // 使われていないのでコメントアウト
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pdfDoc, setPdfDoc] = useState<pdfjs.PDFDocumentProxy | null>(null);

  useEffect(() => {
    let isMounted = true;

    const loadPdf = async () => {
      try {
        // pdfjs.getDocument の引数の型を取得
        type GetDocumentParams = Parameters<typeof pdfjs.getDocument>[0];

        const loadingParams: GetDocumentParams = {
          url: fileUrl,
        };
        const loadingTask = pdfjs.getDocument(loadingParams);

        const pdf = await loadingTask.promise;
        if (!isMounted) return;
        setPdfDoc(pdf);
        setNumPages(pdf.numPages);

        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 2 });

        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (!context) return;

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        const renderTask = page.render(renderContext);
        await renderTask.promise;
      } catch (error) {
        console.error('PDF load error:', error);
        if (isMounted) {
          setNumPages(undefined);
        }
      }
    };

    loadPdf();

    return () => {
      isMounted = false;
      if (pdfDoc) {
        pdfDoc.destroy();
        setPdfDoc(null);
      }
    };
  }, [fileUrl, pageNumber]); // pageNumber を依存配列に追加


  const handleNextPage = () => {
    if (pageNumber < (numPages ?? 1)) { // numPages が undefined の場合は 1 とみなす (安全対策)
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(prevPageNumber => prevPageNumber - 1);
    }
  };


  return (
    <div>

      <canvas ref={canvasRef} style={{ width: 'auto', height: 'auto', maxHeight:'70vh' }} />

      {numPages && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <IconButton onClick={handlePrevPage} disabled={pageNumber === 1}>
            <NavigateBeforeIcon sx={{ fontSize: '2vh' }} />
          </IconButton>
          <span style={{ fontSize: '2vh' }}>
            {pageNumber} / {numPages}
          </span>
          <IconButton onClick={handleNextPage} disabled={pageNumber === numPages}>
            <NavigateNextIcon sx={{ fontSize: '2vh' }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;