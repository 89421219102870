import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import { getFileUrl } from '../../../../api/datas';

import FileViewerDialog from '../../../common/FileViewerDialog';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationProps {
    newsData: Publication[];
}


const NewPublication: React.FC<PublicationProps> = ({ newsData }) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<Publication | null>(null);
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

    const handleCloseFileViewer = () => {
        setOpenFileViewer(false);
        setSelectedFileUrl(null);
    };

    const handleOpenFileDialog = (url: string) => {
        setSelectedFileUrl(url);
        setOpenFileViewer(true);
    };
    function extractFilename(url: string) {
        try {
            const urlObj = new URL(url);
            let pathname = urlObj.pathname;

            // 2回URLデコード
            pathname = decodeURIComponent(pathname);
            pathname = decodeURIComponent(pathname);


            const filenameWithParams = pathname.substring(pathname.lastIndexOf('/') + 1);
            const filename = filenameWithParams.split('?')[0]; // クエリパラメータを除去
            return filename;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }
    const settings = {
        fade: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false, // 画像が複数ある場合のみ矢印を表示
        // 以下を追加
        adaptiveHeight: false, // 画像の高さを自動調整
    };

    const handleSelect = (data: Publication) => {
        setOpenDialog(true);
        setSelectedPost(data);
    }
    const handleClose = () => {
        setOpenDialog(false);
        setSelectedPost(null);
    }
    return (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
                height: '95%',
                mt: 1,
                mr: 2,
                position: 'relative'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h6" sx={{fontSize:'2vh'}}>新着情報</Typography>
            </Box>
            <Divider sx={{ border: '1px solid black', width: '60%' }} />
            {newsData === null && <p>Loading...</p>}
            <List sx={{ width: '100%' }}>
                {newsData?.map((data, index) => (
                    <ListItem
                        key={index}
                        disablePadding
                        sx={{
                            height: '15%',
                            width: '90%',
                            border: '3px solid #5c5c5b',
                            borderRadius: '10px',
                            mb: 3,
                            overflow: 'hidden'
                        }}>
                        <ListItemButton
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '100%',
                                width: '100%',
                                p: 0,
                                pl: 1
                            }}
                            onClick={() => handleSelect(data)}
                        >
                            <ListItemText
                                sx={{ p: 1 }}
                                primary={<Typography variant="body1" noWrap  sx={{
                                    fontSize: '2vh',
                                    overflow: 'hidden',        // はみ出た部分を隠す
                                    textOverflow: 'ellipsis', // 省略記号 (...) を表示
                                    whiteSpace: 'nowrap',    // 折り返しを禁止
                                    display: 'block',         // ブロックレベル要素にする (width を有効にするため)
                                }}>{data.title || 'タイトルなし'}</Typography>}
                            >

                            </ListItemText>


                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Dialog open={openDialog} onClose={() => handleClose()} fullWidth maxWidth="xl" sx={{ overflow: 'scroll' }}>
                <DialogTitle>
                    新着情報
                    <Divider sx={{ border: '2px solid black', width: '40%' }} />
                </DialogTitle>
                <DialogContent sx={{ height: '70vh', overflow: 'scroll' }}>
                    <Typography variant="h5" noWrap sx={{ height: 'fit-content', overflow: 'scroll',fontSize:'2vh' }}>
                        {selectedPost?.title}
                    </Typography>
                    <Typography variant="body1" noWrap sx={{ height: 'fit-content', overflow: 'scroll',fontSize:'2vh' }}>
                        {selectedPost?.startDate} ~ {selectedPost?.endDate}
                    </Typography>
                    <Typography variant="body1" noWrap sx={{ height: '16vh', overflow: 'scroll' ,fontSize:'2vh'}}>
                        {selectedPost?.details}
                    </Typography>
                    {selectedPost?.attachmentUrls && selectedPost?.attachmentUrls.map((url, index) => (
                        url?.indexOf('pdf') > -1 &&
                        <Box key={index} sx={{ mt: 1, mb: 1 }}>
                            <Typography
                                variant='body1'
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                    ,fontSize:'2vh'
                                }}
                                onClick={() => {
                                    handleOpenFileDialog(String(new URL(url)))
                                }}

                            >
                                {extractFilename(url)}
                            </Typography>
                        </Box>
                    ))}
                    {(selectedPost?.attachmentUrls && selectedPost?.attachmentUrls.length > 0) &&
                        <Slider {...settings} className='slider_images'>
                            {
                                selectedPost?.attachmentUrls.map((url, urlIndex) => (
                                    !(url?.indexOf('pdf') > -1) &&
                                    <Box key={url + urlIndex} // urlとurlIndexを組み合わせたkey prop
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, mb: 1 }}>
                                        {url &&
                                            <img src={url} alt={`attachment-${urlIndex}`} style={{ width: '100%', height: '30vh', objectFit: 'contain' }} />
                                        }
                                    </Box>
                                ))
                            }
                        </Slider>
                    }
                </DialogContent>
            </Dialog>
            <FileViewerDialog
                fileUrl={selectedFileUrl || ''}
                open={openFileViewer}
                onClose={handleCloseFileViewer}
            />

        </Box>
    );
};

export default NewPublication;