import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: (result: boolean) => void; // 戻り値として boolean を返す onClose 関数
  title?: string; // ダイアログのタイトル
  message?: string; // ダイアログのメッセージ
  okLabel?: string; // OKボタンのラベル
  cancelLabel?: string; // キャンセルボタンのラベル
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  open,
  onClose,
  title = '確認', // デフォルトのタイトル
  message = '削除しますか？', // デフォルトのメッセージ
  okLabel = 'OK', // デフォルトのOKボタンラベル
  cancelLabel = 'キャンセル', // デフォルトのキャンセルボタンラベル
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)} // 閉じるボタンで false を返す
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={() => onClose(false)}>{cancelLabel}</Button>
        <Button onClick={() => onClose(true)} autoFocus>
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;