import * as api from './api'; // api.ts をインポート

export const downloadQuestionnaire = (questionnaireId: string) => {
  const accountId = sessionStorage.getItem("accountId");
  const mansionId = sessionStorage.getItem("mansionId");
  if (accountId === null || mansionId === null) return;
  try {
    fetch(api.downloadQuestionnaireEndpoint + `?accountId=${accountId}&mansionId=${mansionId}&questionnaireId=${questionnaireId}`, {
      method: 'GET', // POST から GET に変更
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // URLをテキストとして取得
      })
      .then(url => {
        // ダウンロード処理
        const link = document.createElement('a');
        window.open(url, '_blank');
      });
  } catch (error) {
    console.error('Error downloading questionnaire:', error);
    throw error;
  }
};