import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Card,
  Dialog,
  DialogContent,
  TextField,
} from '@mui/material';
//共通コンポーネント
import { useLoadingModal } from '../../common/LoadingModalContext';
import ConfirmDeleteDialog from '../../common/ConfirmDeleteDialog';

//カルーセル
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// color の SVG ファイルをインポート
import 設備情報Icon from '../../../assets/images/icons/color/設備情報.svg';
import お知らせIcon from '../../../assets/images/icons/color/お知らせ.svg';
import 理事会資料Icon from '../../../assets/images/icons/color/理事会資料.svg';
import 注意事項Icon from '../../../assets/images/icons/color/注意事項.svg';
import 工事Icon from '../../../assets/images/icons/color/工事.svg';
import 工程表Icon from '../../../assets/images/icons/color/工程表.svg';
import 書類一覧Icon from '../../../assets/images/icons/color/書類一覧.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//API
import { getPublicationsByCategory } from '../../../api/datas'; // getPublicationsByCategory 関数をインポート
import { createSubCategory } from '../../../api/create';
import { deleteSubCategory } from '../../../api/delete';

interface ListingsManagementProps {
  handleMenuClick: (menu: string) => void; // handleMenuClick の型を定義
}
const ListingsManagement: React.FC<ListingsManagementProps> = ({ handleMenuClick }) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal(); //ローディングの表示非表示

  //新規作成ダイアログ
  const [openCreateSubcategoryModal, setOpenCreateSubcategoryModal] = useState<boolean>(false);
  //削除ダイアログ
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [subcategories, setSubcategories] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState(sessionStorage.getItem('selectedFolder') || '');
  const [subcategoryName, setSubcategoryName] = useState<string>('');

  const [publications, setPublications] = useState<any[]>([]); // 掲載データ

  const fetchPublications = async () => {
    showLoadingModal();
    try {
      const publicationsData = await getPublicationsByCategory(selectedFolder); // getPublicationsByCategory 関数を呼び出す
      setPublications(publicationsData);
    } catch (error) {
      console.error('Error fetching publications:', error);
      // エラーメッセージをユーザーに表示するなど、適切な処理を追加
    } finally {
      hideLoadingModal();
    }
  };

  useEffect(() => {
    if (selectedFolder) {
      fetchPublications();
    }
  }, [selectedFolder]);

  useEffect(() => {
    const mansionData = sessionStorage.getItem('mansions');
    if (!mansionData) return
    const parsedMansionData = JSON.parse(mansionData);
    const selectedMansionId = sessionStorage.getItem('mansionId');
    if (!selectedMansionId) return;
    const selectedMansionData = parsedMansionData.find((mansion: any) => mansion.id === selectedMansionId);
    if(selectedMansionData.categoryForContractor){
      setFolders({...folders,vendor:selectedMansionData.categoryForContractor})
    }
    if(selectedMansionData.categoryForResident){
      setFolders({...folders,resident:selectedMansionData.categoryForResident})
    }
  },[]);

  // カテゴリとサブカテゴリのデータ (不変)
  const signage = [
    { value: 'resident', label: '住民向け' },
    { value: 'vendor', label: '業者向け' },
  ];
  const [folders,setFolders] = useState({
    resident: ['設備情報', 'お知らせ', '理事会資料', '注意事項'],
    vendor: ['工事', 'お知らせ', '工程表', '書類一覧', '注意事項'],
  })
  // サブカテゴリ名に対応するアイコンを取得する関数
  const getIcon = (folder: string) => { // 戻り値の型を React.FunctionComponent に変更
    switch (folder) {
      case '設備情報':
        return 設備情報Icon;
      case 'お知らせ':
        return お知らせIcon;
      case '理事会資料':
        return 理事会資料Icon;
      case '注意事項':
        return 注意事項Icon;
      case '工事':
        return 工事Icon;
      case '工程表':
        return 工程表Icon;
      case '書類一覧':
        return 書類一覧Icon;
      case '洗濯物情報':
        return 工事Icon;
      default:
        return 'null';
    }
  };

  const handleViewDetail = (publication: any) => {
    sessionStorage.setItem(
      'publication',
      JSON.stringify(publication)
    );
    console.log(publication)
    handleMenuClick('listingDetails')
  };

  const handleFolderChange = (folder: string) => {
    setSelectedFolder(folder);
    sessionStorage.setItem('selectedFolder', folder);
  };

  const handleCreateSubcategory = async () => {
    if (!selectedFolder) {
      alert(`カテゴリを選択してください`);
      return;
    }
    if (!subcategoryName || subcategoryName.trim() === '') {
      alert(`サブカテゴリ名を入力してください`);
      return;
    }
    try {
      showLoadingModal();
      const category = selectedFolder;
      const subcategory = subcategoryName;
      //サブカテゴリの作成
      await createSubCategory(category, subcategory);
    } catch (error) {
      alert(`エラーが発生しました: ${error}`);
    } finally {
      setOpenCreateSubcategoryModal(false);
      //サブカテゴリの再読み込み;
      await fetchPublications();
      hideLoadingModal();
      setSubcategoryName('');
    }
  };

  const handleCloseDeleteDialog = async (result:boolean) => {
    if (result === false){
      setOpenDeleteDialog(false);
      return;
    }
    if (!selectedFolder) {
      alert(`カテゴリを選択してください`);
      return;
    }
    if (!subcategoryName || subcategoryName.trim() === '') {
      alert(`サブカテゴリ名を入力してください`);
      return;
    }
    try {
      showLoadingModal();
      const category = selectedFolder;
      const subcategory = subcategoryName;
      //サブカテゴリの作成
      await deleteSubCategory(category, subcategory)
    } catch (error) {
      alert(`エラーが発生しました: ${error}`);
    } finally {
      setOpenDeleteDialog(false);
      //サブカテゴリの再読み込み;
      await fetchPublications();
      hideLoadingModal();
      setSubcategoryName('');
    }
  };

  // カルーセルの設定
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
  };

  return (
    <Box>
      <Box sx={{ height: '5vh' }}>
        <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={() => { handleMenuClick('') }} />
      </Box>
      {/* カテゴリ選択 (カルーセル) */}
      <Box sx={{ backgroundColor: 'lightgray', width: '100vw', height: '20vh', margin: '0', mb: 2 }}>
        <Slider  {...settings} >
          {signage.map((category) => (
            <div key={category.value}>
              <Typography variant="h6" align="center" style={{ marginBottom: '-10px' }}>
                {category.label}
              </Typography>

              {/* カルーセル内にサブカテゴリボタンを表示 */}
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, mt: 2 }}>
                {(folders[category.value as keyof typeof folders] || []).map((folder) => (
                  <Button
                    key={folder}
                    color='inherit'
                    variant={selectedFolder === folder ? 'contained' : 'outlined'}
                    onClick={() => handleFolderChange(folder)}
                    style={{ backgroundColor: selectedFolder === folder ? 'lightgray' : 'white', width: '100px', height: '80px', border: '2px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                  >
                    <img width={50} height={50} src={getIcon(folder)} alt={folder} />
                    <Typography variant='subtitle2' noWrap>{folder}</Typography>
                  </Button>
                ))}
              </Box>
            </div>
          ))}
        </Slider>
      </Box>
      {selectedFolder ?
        <Box sx={{ width: '80vw', margin: 'auto' }}>
          <Box sx={{ display: 'flex', gap: 10, mb: 1 }}>
            <Typography variant="h5" align="left">{selectedFolder}</Typography>
            <Button variant="contained" color="primary" onClick={() => { setOpenCreateSubcategoryModal(true); setSubcategoryName(''); }}><AddIcon />新規追加</Button>
          </Box>
          <Divider style={{ border: '5px solid #65a7e9', marginBottom: '10px' }} />
          <Grid container spacing={5} rowGap={0} style={{ height: '50vh', overflow: 'scroll' }}>
            {publications && publications.map((publication) => (
              <Grid item key={publication.id} xs={12} sm={6} md={4}>
                <Card sx={{ border: '1px solid #ccc' }}>
                  <Box sx={{ width: '100%', height: 'fit-content', backgroundColor: 'whitesmoke', p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">{publication.name}</Typography>
                    <Box>
                      <EditIcon style={{ cursor: 'pointer' }} onClick={() => { handleViewDetail(publication) }} />
                      <DeleteIcon color='error' style={{ cursor: 'pointer' }} onClick={() => { setOpenDeleteDialog(true); setSubcategoryName(publication.name); }} />
                    </Box>
                  </Box>
                  <Box sx={{ height: '80px', p: 2 }}>
                    <Typography variant="body1">作成日：{new Date(publication?.createdAt?._seconds * 1000).toLocaleDateString('sv-SE')}</Typography>
                    <Typography variant="body1">更新日：{new Date(publication?.updatedAt?._seconds * 1000).toLocaleDateString('sv-SE')}</Typography>
                  </Box>
                  {/* その他の掲載情報 */}
                </Card>
              </Grid>
            ))}

          </Grid>
        </Box>
        : <Typography variant='h6' align='center'
        >カテゴリを選択してください</Typography>
      }
      <Dialog open={openCreateSubcategoryModal} onClose={() => setOpenCreateSubcategoryModal(false)}>
        <DialogContent>
          <Typography variant="h6" component="h2">
            新規追加
          </Typography>
          <TextField value={subcategoryName} onChange={(event) => setSubcategoryName(event.target.value)} label="件名" fullWidth margin="normal" />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={() => {
              // 新規サブカテゴリの追加処理
              handleCreateSubcategory();
            }}>
              追加
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmDeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        title="削除確認"
        message="本当に削除しますか？"
        okLabel="削除"
        cancelLabel="キャンセル"
      />
    </Box>
  );
};

export default ListingsManagement;