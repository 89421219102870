import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Typography,
    Box,
    Divider,
    Card,
    CardContent,
    Grid2 as Grid,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './NewPublication.scss';
import { getFileUrl } from '../../../../api/datas';

//ファイル表示
import FileViewerDialog from '../../../common/FileViewerDialog';

interface Publication {
    id: string;
    createdAt?: { _seconds: number; _nanoseconds: number };
    updatedAt?: { _seconds: number; _nanoseconds: number };
    attachmentUrls?: string[];
    title?: string;
    details?: string;
    startDate?: string;
    endDate?: string;
    uploadedAt?: { _seconds: number; _nanoseconds: number };
}

interface PublicationProps {
    newsData: Publication[];
}

const NewPublication: React.FC<PublicationProps> = ({ newsData }) => {

    const CustomNextArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <Box
                style={{
                    ...style,
                    position: 'absolute',
                    top: '50%',
                    right: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    cursor: 'pointer'
                }} // 例: 背景色を緑にする
                onClick={onClick}
            >
                <NavigateNextIcon color='inherit' sx={{ fontSize: '5vw', }} />
            </Box>
        );
    }

    const CustomPrevArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <Box
                style={{
                    ...style,
                    position: 'absolute',
                    top: '50%',
                    left: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    cursor: 'pointer'
                }} // 例: 背景色を赤にする
                onClick={onClick}
            >
                <NavigateBeforeIcon color='inherit' sx={{ fontSize: '5vw' }} />
            </Box>
        );
    }
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: newsData?.length > 1 ? true : false,
        adaptiveHeight: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };
    const imageSliderSetting = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        adaptiveHeight: true,
    }
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
    const handleOpenFileDialog = (url: string) => {
        setSelectedFileUrl(url);
        setOpenFileViewer(true);
    };

    const handleCloseFileDialog = () => {
        setOpenFileViewer(false);
        setSelectedFileUrl(null);
    };
    const extractFilename = (url: string) => {
        try {
            const urlObj = new URL(url);
            let pathname = urlObj.pathname;
            // 2回URLデコード
            pathname = decodeURIComponent(pathname);
            pathname = decodeURIComponent(pathname);
            const encodedFilename = pathname.substring(pathname.lastIndexOf('/') + 1);
            const decodedFilename = decodeURIComponent(encodedFilename);
            const filename = decodedFilename.split('?')[0]; // クエリパラメータを除去
            return filename;
        } catch (error) {
            console.error("Invalid URL:", error);
            return null;
        }
    }

    return (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
                height: '95%',
                mt: '2%',
                position: 'relative'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6">新着情報</Typography>
            </Box>
            <Divider sx={{ border: '1px solid black', width: '95%' }} />
            {newsData === null && <p>Loading...</p>}
            {newsData &&
                <Slider {...settings} className='slider_publication'>
                    {(Array.isArray(newsData) && newsData?.length === 0) && <p>Loading...</p>}
                    {newsData?.map((data, index) => (
                        <Card
                            key={newsData[index].id}
                            sx={{
                                width: '40vw !important',
                                height: '60vh',
                                border: '1px solid black',
                                borderRadius: '10px',
                                mt: 1
                            }}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                overflow: 'scroll',
                                height: '100%',
                                width: '100%'
                            }}>
                                <Typography variant="h6">
                                    {data.title}
                                </Typography>
                                <Typography variant="body1">
                                    {data.startDate} ~ {data.endDate}
                                </Typography>
                                <Box sx={{
                                    height: '15vh',
                                    width: '100%',
                                    overflow: 'scroll',
                                    border: '1px solid black',
                                    borderRadius: '10px',
                                    textAlign: 'left',
                                    p: 1
                                }}
                                >
                                    <Typography variant="body1">
                                        {data.details}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    height: '15vh',
                                    width: '100%',
                                }}
                                >
                                    {data.attachmentUrls && data.attachmentUrls.length > 0 &&
                                        <Box>
                                            <Grid direction={'row'}>
                                                {data.attachmentUrls.map((url, urlIndex) => (
                                                    <Grid key={url + urlIndex} // urlとurlIndexを組み合わせたkey prop
                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, mt: 1 }}>
                                                        {url && (url?.indexOf('pdf') > -1) &&
                                                            <Typography 
                                                            variant="body1" 
                                                            sx={{
                                                                border:'2px solid black', 
                                                                borderRadius: '10px',
                                                                p:1,
                                                                textDecoration:'underline',
                                                                cursor:'pointer'
                                                            }}
                                                            onClick={() => {
                                                                handleOpenFileDialog(String(new URL(url)))
                                                            }}>
                                                                {
                                                                    //ファイル名だけを抽出
                                                                    extractFilename(url)
                                                                }
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Slider {...imageSliderSetting} className='slider_images'>
                                                {
                                                    data.attachmentUrls.map((url, urlIndex) => (
                                                        !(url?.indexOf('pdf') > -1) &&
                                                        <Box key={url + urlIndex} // urlとurlIndexを組み合わせたkey prop
                                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, mb: 1 }}>
                                                            {url &&
                                                                <img src={url} alt={`attachment-${urlIndex}`} style={{ width: '100%', height: '30vh', objectFit: 'contain' }} />
                                                            }
                                                        </Box>
                                                    ))
                                                }
                                            </Slider>
                                        </Box>
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Slider>
            }
            <FileViewerDialog
                fileUrl={selectedFileUrl || ''}
                open={openFileViewer}
                onClose={() => setOpenFileViewer(false)}
            />
        </Box>
    );
};

export default NewPublication;