import React from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemText,
    ListItemButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    CircularProgress,
    Backdrop,
    Divider
} from '@mui/material';
//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//common
import Question from '../../common/Question';
import QuestionnaireDialog from '../../common/QuestionnaireDialog';

//loading
import { useLoadingModal } from '../../common/LoadingModalContext';
//apis
import { getQuestionnaires } from '../../../api/datas';
import { createQuestionnaire } from '../../../api/create';
import { updateQuestionnaire } from '../../../api/update';
import { deleteQuestionnaire } from '../../../api/delete';
import { downloadQuestionnaire } from '../../../api/download';

import AnswerListDialog from '../../common/AnswerListDialog';
import { useState, useEffect, useRef } from 'react';

interface QuestionnaireManagementProps {
    handleMenuClick: (menu: string) => void;
}

const QuestionnaireManagement: React.FC<QuestionnaireManagementProps> = ({ handleMenuClick }) => {
    const { showLoadingModal, hideLoadingModal } = useLoadingModal();
    const [questionnaires, setQuestionnaires] = useState<any[]>([]);
    const [newQuestionnaire, setNewQuestionnaire] = useState<any>({
        title: '',
        questions: [],
        startDate: '',
        endDate: '',
    });
    const [editingQuestionnaire, setEditingQuestionnaire] = useState<any | null>(null);
    const [expandedQuestionnaireId, setExpandedQuestionnaireId] = useState<string | null>(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenAnswerListDialog, setIsOpenAnswerListDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filteredQuestionnaires, setFilteredQuestionnaires] = useState<any[]>([]);
    const fetchQuestionnaires = async () => {
        showLoadingModal();
        try {
            const questionnairesData = await getQuestionnaires(); // getQuestionnaires 関数を呼び出す
            // **ここからソート処理**
            const sortedQuestionnaires = questionnairesData.sort((a: any, b: any) => {
                const aCreatedAt = a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000;
                const bCreatedAt = b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000;
                return bCreatedAt - aCreatedAt; // 新しいものが先頭になるように降順にソート
            });
            setQuestionnaires(sortedQuestionnaires);
            setFilteredQuestionnaires(sortedQuestionnaires);
            setSearchKeyword('');
            console.log(questionnairesData);
        } catch (error) {
            console.error('Error fetching questionnaires:', error);
            // エラーメッセージをユーザーに表示するなど、適切な処理を追加
        } finally {
            hideLoadingModal();
        }
    };

    useEffect(() => {
        fetchQuestionnaires();
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);
        const filtered = questionnaires.filter((questionnaire) =>
            questionnaire?.title?.includes(keyword)
        );
        setFilteredQuestionnaires(filtered);
    };

    const handleOpenDialog = (isEditing: boolean, questionnaire?: any) => {
        setIsOpenDialog(true);
        setIsEditing(isEditing);
        if (isEditing) {
            setEditingQuestionnaire(questionnaire);
        } else {
            setNewQuestionnaire({
                title: '',
                questions: [],
                startDate: '',
                endDate: '',
            });
        }
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setEditingQuestionnaire(null);
    };

    const handleSaveQuestionnaire = async (questionnaire: any) => {
        console.log(questionnaire);
        if (isEditing) {
            handleUpdateQuestionnaire(questionnaire);
        } else {
            handleCreateQuestionnaire(questionnaire);
        }

    };
    const handleUpdateQuestionnaire = async (questionnaire: any) => {
        showLoadingModal();
        try {
            console.log('fetched', questionnaire);
            const response = await updateQuestionnaire(
                questionnaire
            );
        } catch (error) {
            console.error('Error updating questionnaire:', error);
            alert('アンケートの更新に失敗しました。');
        } finally {
            hideLoadingModal();
            handleCloseDialog();
            await fetchQuestionnaires();
        }
    };

    const handleCreateQuestionnaire = async (questionnaire: any) => {
        showLoadingModal();
        try {
            const response = await createQuestionnaire(
                questionnaire
            );
        } catch (error) {
            console.error('Error creating questionnaire:', error);
            alert('アンケートの作成に失敗しました。');
        } finally {
            hideLoadingModal();
            handleCloseDialog();
            await fetchQuestionnaires();
        }
    };

    const handleDeleteQuestionnaire = async (questionnaireId: string) => {
        showLoadingModal();
        try {
            await deleteQuestionnaire(questionnaireId);
        } catch (error) {
            console.error('Error deleting questionnaire:', error);
            alert('アンケートの削除に失敗しました。');
        } finally {
            hideLoadingModal();
            await fetchQuestionnaires();
        }
    };

    const handleOpenAnswerListDialog = (questionnaire: any) => {
        setIsOpenAnswerListDialog(true);
    };

    const handleCloseAnswerListDialog = () => {
        setIsOpenAnswerListDialog(false);
    };
    const handleRespondentClick = (respondentId: string) => {
        // 回答内容ダイアログを開く処理
        console.log('回答者ID:', respondentId);
    };
    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ height: '5vh' }}>
                <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={() => { handleMenuClick('') }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h4" style={{ marginLeft: '10px', marginRight: '30px' }}>
                    アンケート一覧
                </Typography>
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog(false)}>
                    <AddIcon /> アンケートを追加
                </Button>
            </Box>
            <Divider style={{ border: '5px solid #65a7e9', marginBottom: '10px' }} />
            <Box sx={{ width: '80vw', marginLeft: 'auto', marginRight: 'auto' }}>
                <TextField
                    value={searchKeyword}
                    onChange={handleSearchChange}
                    placeholder="アンケート名検索"
                    fullWidth
                    sx={{ mb: 2 }}
                />
                {
                    filteredQuestionnaires ?
                        <Box>
                            {
                                filteredQuestionnaires?.map((questionnaire) => (
                                    <Accordion key={questionnaire.id}
                                        expanded={expandedQuestionnaireId === questionnaire.id}
                                        elevation={0}
                                        square
                                        sx={{
                                            position: 'relative',
                                            boxShadow: expandedQuestionnaireId === questionnaire.id ? '3px 3px 7px 0px rgba(0, 0, 0, 0.4)' : 'none',
                                            '& .MuiAccordionSummary-content': {
                                                marginLeft: 10,
                                            },
                                            '& .MuiAccordionSummary-expandIconWrapper': {
                                                position: 'absolute',
                                                left: 20
                                            }
                                        }}
                                    >
                                        <AccordionSummary key={questionnaire.id}
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{ borderBottom: '1px solid #ccc' }}
                                            onClick={() => { setExpandedQuestionnaireId(expandedQuestionnaireId === questionnaire.id ? null : questionnaire.id) }}
                                        >
                                            <ListItemText primary={questionnaire?.title} />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    display: (new Date(questionnaire.startDate) < new Date() && new Date() < new Date(questionnaire.endDate)) ? 'none' : 'block'
                                                }}
                                            >
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleOpenDialog(true, questionnaire);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteQuestionnaire(questionnaire.id);
                                                }}>
                                                    <DeleteIcon color='error' />
                                                </IconButton>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pl: 12 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography>掲載期間：{questionnaire?.startDate}〜{questionnaire?.endDate}</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                                    <Button
                                                        variant='text'
                                                        sx={{}}
                                                        onClick={async () => {
                                                            showLoadingModal();
                                                            await downloadQuestionnaire(questionnaire.id);
                                                            hideLoadingModal();
                                                        }
                                                        }
                                                    >
                                                        CSVエクスポート
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#fa8f5a !important',
                                                            color: 'white !important',
                                                            ':hover': {
                                                                backgroundColor: '#e47740 !important'
                                                            }
                                                        }}
                                                        onClick={() => handleOpenAnswerListDialog(questionnaire)}
                                                    >
                                                        回答一覧
                                                    </Button>
                                                </Box>
                                            </Box>
                                            {questionnaire?.questions.map((question: any, index: number) => (
                                                <Question
                                                    key={index}
                                                    question={question}
                                                    answer={''}
                                                    onAnswerChange={() => { return }}
                                                    editable={false} // AccordionDetails内では編集不可にする
                                                    isPreview={true} // プレビューモードに設定
                                                />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </Box>
                        :
                        <Typography>
                            アンケートがありません
                        </Typography>
                }
            </Box>
            <QuestionnaireDialog
                open={isOpenDialog}
                onClose={handleCloseDialog}
                onSave={handleSaveQuestionnaire}
                questionnaire={isEditing ? editingQuestionnaire : undefined}
                isEditing={isEditing}
            />
            <AnswerListDialog
                open={isOpenAnswerListDialog}
                onClose={handleCloseAnswerListDialog}
                questionnaire={questionnaires.find((questionnaire) => questionnaire.id === expandedQuestionnaireId) || ''}
            />
        </Box>
    );
};

export default QuestionnaireManagement;